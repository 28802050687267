export const MODULOS_VALUES = {
  RRHH: 1,
  FINANZAS: 2,
  BITACORA: 3,
  GESTIONOT: 4,
  ADMUSUARIOS:5
};


export const PERFILES_VALUES_RRHH = {
  ADMIN: 1,
  USERBASIC: 2,
  ASISADM: 3,
  GERENTE: 4,
  JEFEAREA:7
};

export const PERFILES_VALUES_FINANZAS = {
  SOLICITANTE: 5,
  APROBADOR: 6
};

export const PERFILES_VALUES_BITACORA = {
  ADMINISTRADOR_VEHICULOS: 8,
  ADMINISTRADOR_EQUIPOS: 9,
  SOLICITANTE_VEHICULOS: 10,
  SOLICITANTE_EQUIPOS:11
};

export const PERFILES_VALUES_GESTION = {
  GERENTE: 4,
  JEFE_PROYECTO: 12,
  INGENIERO:13
};
