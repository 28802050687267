import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import { es_language } from 'src/app/utils/datetable.lenguaje.config';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SessionService} from '../../../providers/session.service';
import {ToastOptions, ToastyConfig, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { EstadoDenunciasService } from '../../services/estado.denuncias.service';
import { EstadoDenuncia } from '../../model/estado.denuncia.model';

@Component({
    selector: 'app-estadodenuncia',
    templateUrl: './estadodenuncia.component.html'
})
export class EstadoDenunciaComponent implements OnInit {

    constructor(
        private sessionService: SessionService,
        private estadoDenunciaService: EstadoDenunciasService,
        private router: Router,
        private toastyService: ToastyService,

    ) {
       
    }

    formEstadoDenuncia = new FormGroup({
        nombreEstadoDenunciaControl: new FormControl('', [Validators.required]),
        activoEstadoDenunciaControl: new FormControl('',null) 
    });

    estadosdenuncias: EstadoDenuncia [] = [];
    codigoEstadoDenuncia;
    estadoDenunciaSelected;
    tituloFormEstadoDenuncia = 'Nuevo Registro';
    editEstadoDenunciaMode = false;
    public table;

    ngOnInit() {
 
      this.cargaEstadosDenuncias();

    }


    refrescaEstadoDenunciasModal(){
      this.codigoEstadoDenuncia =null;
      this.tituloFormEstadoDenuncia = 'Nuevo Registro';
      this.editEstadoDenunciaMode = false;
      //this.estadoDenunciaSelected = null;
      this.formEstadoDenuncia.reset();
    
    }


    cargaEstadosDenuncias() {

      this.estadoDenunciaService.getListaEstadoDenuncias()
        .subscribe(
          (estadodenuncia: any[]) => {
            this.estadosdenuncias = estadodenuncia['data'];
            this.cargaTablaEstadoDenuncias(this.estadosdenuncias);
          }
        );
    }
  
    cargaTablaEstadoDenuncias(estadosdenuncias) {
      let numero = 0;
      this.table = $('#tableEstadoDenuncias').DataTable({
        responsive: true,
        searching: true,
        data: estadosdenuncias,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            width: "75%",
            title: 'Nombre', data: 'nombre'},
          {
            width: "20%",
            title: 'Estado', data: function (row) {
              if (row.activo) {
                return 'Activo';
              } else {
                return 'Inactivo';
              }
            }
          }
  
        ],
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.estadoDenunciaSelected = data;
          });
  
          return row;
        }
      });    
    }

   

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formEstadoDenuncia.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formEstadoDenuncia.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formEstadoDenuncia.controls).forEach(key => {
            this.formEstadoDenuncia.get(key).markAsTouched();
            this.formEstadoDenuncia.get(key).updateValueAndValidity();
            if (this.formEstadoDenuncia.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarEstadoDenuncia() {
      let data = this.estadoDenunciaSelected;
      this.codigoEstadoDenuncia = data['id'];
      $('#estadoDenunciaModal').click();
      this.tituloFormEstadoDenuncia = 'Editar Registro';
      this.editEstadoDenunciaMode = true;
      this.formEstadoDenuncia.get('nombreEstadoDenunciaControl').setValue(data['nombre']);
      this.formEstadoDenuncia.get('activoEstadoDenunciaControl').setValue(data['activo']); 
  
    }
    
    nuevoEstadoDenuncia() {

      const formValid = this.validarFormulario();
      if (formValid === true) {
  
        const control = this.formEstadoDenuncia.controls;
        const estadodenuncias: EstadoDenuncia = {
          id: this.codigoEstadoDenuncia,
          nombre: control['nombreEstadoDenunciaControl'].value,
          activo: true
        };
  
        let mensajeExito = '';
        let mensajeError = '';
  
        if (!this.editEstadoDenunciaMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.estadoDenunciaService.setNuevoEstadoDenuncia(estadodenuncias)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoEstadoDenuncia = null;
                  this.estadoDenunciaSelected = null;            
                  this.formEstadoDenuncia.reset();
                  this.tituloFormEstadoDenuncia = 'Nuevo Registro';
                  $('#btn-cerrar-modal-estado-denuncia').click();
                  $('#tableEstadoDenuncias').DataTable().destroy();
                  this.cargaEstadosDenuncias();
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } else {
          estadodenuncias.activo = control['activoEstadoDenunciaControl'].value;
          mensajeExito = 'Registro editado correctamente';
          mensajeError = 'Registro no pudo ser editado';
  
          this.estadoDenunciaService.setNuevoEstadoDenuncia(estadodenuncias)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoEstadoDenuncia = null;
                  this.estadoDenunciaSelected = null;         
                  this.formEstadoDenuncia.reset();
                  this.tituloFormEstadoDenuncia = 'Nuevo Registro';
                  $('#btn-cerrar-modal-estado-denuncia').click();
                  $('#tableEstadoDenuncias').DataTable().destroy();
                  this.cargaEstadosDenuncias();
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
            
        }
      }
    }
  
    deleteEstadoDenuncia(){
        swal({
          title: "Estás seguro que deseas eliminar el registro?",   
          text: "No podrás recuperar estos datos!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo eliminar!"
        }).then((confirm) => {
          if (confirm['value']) {
  
              /* eliminar registro */
              const id = this.estadoDenunciaSelected['id'];
              this.estadoDenunciaService.deleteEstadoDenuncia(id)
              .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    if (success) {
                        this.addToast('Éxito', 'Registro eliminado exitosamente', 'success');
                        this.editEstadoDenunciaMode=false;
                        this.estadoDenunciaSelected=null;
                        this.codigoEstadoDenuncia=null;
                        $('#tableEstadoDenuncias').DataTable().destroy();
                        this.cargaEstadosDenuncias();                    
                    } else {
                        this.addToast('Error', 'No se pudo eliminar el registro', 'error');
                    }
                }
            );
          }
        });
    }

    volverAdministracion(){
      this.router.navigateByUrl('/canal-denuncias/administracion');

  }

}
