import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/auth.service';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { ToasMsgService } from 'src/app/services/toas.msg.service';
import { SessionService } from 'src/app/providers/session.service';
import swal from 'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component ({
    templateUrl: 'restablecer.contrasena.component.html'
})

export class RestablecerContrasenaComponent {

    public form: FormGroup;
    public id: String;
    constructor(public fb: FormBuilder, 
                public router:Router,
                private sessionService:SessionService,
                private authService:AuthenticationService,
                private toasMsgService: ToasMsgService,
                private route: ActivatedRoute) {
        
        this.form = this.fb.group({
            'password': [null, Validators.compose([Validators.required])],
            'passwordValidacion': [null, Validators.compose([Validators.required])]
        });
        //Validators.minLength(6)
    }
    ngOnInit() {
      let param  = this.route.snapshot.params.id;
      this.id = param;
    }

    public onSubmit(values:Object):void {
        if (this.form.valid) {
          let controls = this.form.controls;

            if(controls['password'].value == controls['passwordValidacion'].value){
              if(controls['password'].value.length >= 4){
                let formRestablecerContrasena= {
                  restablecer: this.id,
                  password: controls['password'].value
                }
                this.authService.restablecerContrasena(formRestablecerContrasena).subscribe(
                  result => {
                    let resp = JSON.parse(JSON.stringify(result));
                    if(resp.success){
                      //this.toasMsgService.addToast('Éxito', resp.message, 'success');
                      Swal.fire('Éxito', resp.message, 'success')
                      setTimeout(function () {
                        //this.router.navigate(['/login']);
                        //window.location.href = '/login';
                        window.location.href = '/';
                      }, 5000);
                     // this.router.navigate(['/login']);
                    }else{
                      this.toasMsgService.addToast('Advertencia', resp.message, 'error');
                    }
              });
              }else{
                this.toasMsgService.addToast('Advertencia', 'La contraseña no cumple con el mínimo de caracteres (4)', 'error');
              }
            }else{
              this.toasMsgService.addToast('Advertencia', 'Las contraseñas no son iguales', 'error');
            }
           
                     
        }else{
            this.toasMsgService.addToast('Advertencia', 'Debe completar el formulario', 'wait');
        }

    }

  

}
