import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { TIPOS_DENUNCIA } from '../services/constant.denuncias.service';

@Component ({
    templateUrl: 'inicio.html'
})

export class InicioComponent implements OnInit  {
    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService
    ) { }

    ngOnInit() {
        this.canalDenunciasService.canalDenuncias=true;
        this.canalDenunciasService.tipoDenuncia=null;
    }


    irAPrevencionDelito() {
        this.canalDenunciasService.tipoDenuncia= TIPOS_DENUNCIA.PREVENCION_DELITO;
        this.canalDenunciasService.esAnonimoValue= true;
        this.router.navigateByUrl('/canal-denuncias/steep1');
    }

    irALeyKarin() {
        this.canalDenunciasService.tipoDenuncia= TIPOS_DENUNCIA.LEY_KARIN;
        this.canalDenunciasService.esAnonimoValue= false;
        this.router.navigateByUrl('/canal-denuncias/steep1');
    }

    irASeguimiento() {
        this.canalDenunciasService.tipoDenuncia= TIPOS_DENUNCIA.SEGUIMIENTO;
        this.router.navigateByUrl('/canal-denuncias/seguimiento');
    }
}