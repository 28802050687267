import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CanalDenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public tipoAdministrador: number;
    public isDenunciante: Boolean;

    /* Objeto denuncia */
    public tipoDenuncia: number;
    public relacionDenuncia: number;
    public itemDenuncia: number;
    public regionDenuncia: number;
    public detalleRegionDenuncia: string;
    public tiempoDenuncia: number;
    public detalleInvolucradosDenuncia: string;
    public descripcion: string;
    public esAnonimo: string;
    public nombreDenunciante: string;
    public apellidoDenunciante: string;
    public correoDenunciante: string;
    public numeroDocumento: string;
    public esAnonimoValue: boolean;

    public identificadorDenuncia: number;
    public codigoDenuncia: string;
    public passwordDenunciante: string;

    public formData: FormData;

    public volverSteep1: boolean;
    public volverSteep2: boolean;
    public volverSteep3: boolean;
    
    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
      this.volverSteep1=false;
      this.volverSteep2=false;
      this.volverSteep3=false;
      
    }

}
