import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ValidarFormulariosUtils {

    constructor() {

    }

    public validarFormulario(formulario) {
      let isValid = true;
      Object.keys(formulario.controls).forEach(key => {
          formulario.get(key).markAsTouched();
          formulario.get(key).updateValueAndValidity();
         
          if (formulario.get(key).invalid) {
            isValid = false;
          }
      });
      return isValid;
    }



}


