import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { TIPOS_DENUNCIA } from '../services/constant.denuncias.service';

@Component ({
    templateUrl: 'administracion.component.html'
})

export class AdministracionCanalDenunciasComponent implements OnInit  {
    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService
    ) { }

    ngOnInit() {
        if(this.canalDenunciasService.isAdmimnistrador){
            this.canalDenunciasService.canalDenuncias=true;
            this.canalDenunciasService.tipoDenuncia=null;
        }else{
            this.router.navigateByUrl('canal-denuncias/undefined');
        }
        
    }

    irADenuncias() {
        this.router.navigateByUrl('/canal-denuncias/administracion/denuncias');
    }

    irALeyes() {
        this.router.navigateByUrl('/canal-denuncias/administracion/tipodenuncias');
    }

    irADelitos() {
        this.router.navigateByUrl('/canal-denuncias/administracion/delitos');
    }

    irAEstadoDenuncia() {
        this.router.navigateByUrl('/canal-denuncias/administracion/estadodenuncias');
    }

    irATipoRelacion() {
      this.router.navigateByUrl('/canal-denuncias/administracion/tiporelacion');
    }

    irATiempoTranscurrido() {
      this.router.navigateByUrl('/canal-denuncias/administracion/periodicidades');
    }

    irARegiones() {
      this.router.navigateByUrl('/canal-denuncias/administracion/regiones');
    }

    cerrarSesion(){
        this.canalDenunciasService.isAdmimnistrador=false;
        this.router.navigateByUrl('/canal-denuncias');
    }
}