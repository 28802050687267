import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { TipoRelacionDenunciasService } from '../../services/tipo.relacion.denuncias.service';
import { TipoRelacion } from '../../model/tipo.relacion.model';
import { TipoDenunciasService } from '../../services/tipo.denuncias.service';
import { TipoDenuncia } from '../../model/tipo.denuncia.model';

@Component({
    selector: 'app-tiporelacion',
    templateUrl: './tiporelacion.component.html'
})
export class TipoRelacionComponent implements OnInit {

    constructor(
        private tipoRelacionService: TipoRelacionDenunciasService,
        private tipoDenunciaService: TipoDenunciasService,
        private router: Router,
        private toastyService: ToastyService,

    ) {
       
    }

    formFilter = new FormGroup({
      filterTipoDenunciaControl: new FormControl('')
    });

    formTipoRelacion = new FormGroup({
      tipoDenunciaControl: new FormControl('', [Validators.required]),
      nombreTipoRelacionControl: new FormControl('', [Validators.required]),
      activoTipoRelacionControl: new FormControl('',null) 
    });

    tiposrelacion: TipoRelacion [] = [];
    tiposdenuncias: TipoDenuncia [] = [];
    codigoTipoRelacion;
    tipoRelacionSelected;
    tituloFormTipoRelacion = 'Nuevo Registro';
    editTipoRelacionMode = false;
    public table;
    /* filter */
    public urlFilter='?';

    ngOnInit() {
 
      this.urlFilter += '';
      this.cargaTiposRelacion(this.urlFilter);
      this.obtenerTiposDeDenunciaFilter();

    }


    refrescaTipoRelacionModal(){
      this.codigoTipoRelacion =null;
      this.tituloFormTipoRelacion = 'Nuevo Registro';
      this.editTipoRelacionMode = false;
      this.formTipoRelacion.reset();
    
    }

    obtenerTiposDeDenunciaFilter(){
      this.tipoDenunciaService.getListaTipoDenuncias()
          .subscribe(
              (tiposdenuncias: any[]) => {               
                  this.tiposdenuncias = tiposdenuncias['data'];
              }
          );
    }

    obtenerTiposDeDenuncia(){
      this.cargaTipoDenuncia();
    }

    cargaTipoDenuncia() {
      this.tipoDenunciaService.getListaTipoDenuncias()
          .subscribe(
              (tiposdenuncias: any[]) => {               
                  this.tiposdenuncias = tiposdenuncias['data'];
              }
          );
    }

    changeFiltros() {

      const control = this.formFilter.controls;
      let filterTipoDenuncia = control['filterTipoDenunciaControl'].value; 

      this.urlFilter = '?';

      if (filterTipoDenuncia !== null && filterTipoDenuncia !== '') {
        this.urlFilter += 'tipodenuncia=' + filterTipoDenuncia + '&';
      }else{
        filterTipoDenuncia = '0';
        this.urlFilter += 'tipodenuncia=' + filterTipoDenuncia + '&';
      }

      this.urlFilter = this.urlFilter.substring(0, this.urlFilter.length - 1);
      $('#tableTipoRelacion').DataTable().destroy();
      this.tipoRelacionSelected = null;
      this.cargaTiposRelacion(this.urlFilter)

     
    }

    cargaTiposRelacion(urlFilter){

      this.tipoRelacionService.getListaTipoRelacionByTipo(urlFilter)
      .subscribe(
        (tiporelacion: any[]) => {
          this.tiposrelacion = tiporelacion['data'];
          this.cargaTablaTipoRelacion(this.tiposrelacion);
        }
      );
    }
  
    cargaTablaTipoRelacion(tiposrelacion) {
      let numero = 0;
      this.table = $('#tableTipoRelacion').DataTable({
        responsive: true,
        searching: true,
        data: tiposrelacion,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            width: "25%",
            title: 'Nombre', data: 'nombre'
          },
          {
            title: 'Tipo Denuncia', data: (row) => {
              if(row.tipoDenuncia!=null){
                return row['tipoDenuncia'].nombre;
              }else{
                return 'No definida';
              }    
            }
          },
          {
            width: "35%",
            title: 'Estado', data: function (row) {
              if (row.activo) {
                return 'Activo';
              } else {
                return 'Inactivo';
              }
            }
          }
  
        ],
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.tipoRelacionSelected = data;
          });
  
          return row;
        }
      });    
    }

   

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formTipoRelacion.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formTipoRelacion.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formTipoRelacion.controls).forEach(key => {
            this.formTipoRelacion.get(key).markAsTouched();
            this.formTipoRelacion.get(key).updateValueAndValidity();
            if (this.formTipoRelacion.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarTipoRelacion() {
      let data = this.tipoRelacionSelected;
      this.codigoTipoRelacion = data['id'];
      $('#tipoRelacionModal').click();
      this.tituloFormTipoRelacion = 'Editar Registro';
      this.editTipoRelacionMode = true;
      this.formTipoRelacion.get('tipoDenunciaControl').setValue(data['tipoDenuncia.id']);
      this.formTipoRelacion.get('nombreTipoRelacionControl').setValue(data['nombre']);
      this.formTipoRelacion.get('activoTipoRelacionControl').setValue(data['activo']); 
  
    }
    
    nuevoTipoRelacion() {

      const formValid = this.validarFormulario();
      if (formValid === true) {
  
        const control = this.formTipoRelacion.controls;
        const tiporelacion: TipoRelacion = {
          id: this.codigoTipoRelacion,
          tipoDenuncia: {id: control['tipoDenunciaControl'].value},
          nombre: control['nombreTipoRelacionControl'].value,
          activo: true
        };
  
        let mensajeExito = '';
        let mensajeError = '';
  
        if (!this.editTipoRelacionMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.tipoRelacionService.setNuevoTipoRelacion(tiporelacion)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoTipoRelacion = null;
                  this.tipoRelacionSelected = null;            
                  this.formTipoRelacion.reset();
                  this.tituloFormTipoRelacion = 'Nuevo Registro';
                  $('#btn-cerrar-modal-tipo-relacion').click();
                  $('#tableTiposRelacion').DataTable().destroy();
                  this.cargaTiposRelacion(this.urlFilter);
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } else {
          tiporelacion.activo = control['activoTipoRelacionControl'].value;
          mensajeExito = 'Registro editado correctamente';
          mensajeError = 'Registro no pudo ser editado';
  
          this.tipoRelacionService.setNuevoTipoRelacion(tiporelacion)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoTipoRelacion = null;
                  this.tipoRelacionSelected = null;         
                  this.formTipoRelacion.reset();
                  this.tituloFormTipoRelacion = 'Nuevo Registro';
                  $('#btn-cerrar-modal-tipo-relacion').click();
                  $('#tableTipoRelacion').DataTable().destroy();
                  this.cargaTiposRelacion(this.urlFilter);
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
            
        }
      }
    }
  
    deleteTipoRelacion(){
        swal({
          title: "Estás seguro que deseas eliminar el registro?",   
          text: "No podrás recuperar estos datos!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo eliminar!"
        }).then((confirm) => {
          if (confirm['value']) {
  
              /* eliminar registro */
              const id = this.tipoRelacionSelected['id'];
              this.tipoRelacionService.deleteTipoRelacion(id)
              .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    if (success) {
                        this.addToast('Éxito', 'Registro eliminado exitosamente', 'success');
                        this.editTipoRelacionMode=false;
                        this.tipoRelacionSelected=null;
                        this.codigoTipoRelacion=null;
                        $('#tableTipoRelacion').DataTable().destroy();
                        this.cargaTiposRelacion(this.urlFilter);                    
                    } else {
                        this.addToast('Error', 'No se pudo eliminar el registro', 'error');
                    }
                }
            );
          }
        });
    }

    volverAdministracion(){
      this.router.navigateByUrl('/canal-denuncias/administracion');
    }

}
