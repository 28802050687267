import { Component } from '@angular/core';
import { TemplateService } from '../template.service';
import { SessionService } from '../../providers/session.service';
import { AuthenticationService } from 'src/app/providers/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent  {

    searchModel : string;
    isCollapse: boolean;
    isOpen: boolean;
    searchActived: boolean = false;
    username;

    constructor(private tplSvc: TemplateService, 
                private sessionService: SessionService,
                public router:Router, 
                private authService:AuthenticationService) {
    }

    ngOnInit(): void {
        //console.log(this.authService.getUsuario());
        this.username = this.sessionService.getUsuario().nombre;
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
        this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);
    }

    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }

    toggleSidePanelOpen() {
        this.isOpen = !this.isOpen;
        this.tplSvc.toggleSidePanelOpen(this.isOpen);
    }

    toggleSearch () {
        this.searchActived = !this.searchActived;
        console.log(this.searchActived)
    }

    public logout() {
        this.sessionService.logOut();
        this.router.navigate(['/login']);
    }
}
