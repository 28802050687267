import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/auth.service';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { ToasMsgService } from 'src/app/services/toas.msg.service';
import { SessionService } from 'src/app/providers/session.service';
import swal from 'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component ({
    templateUrl: 'login.component.html'
})

export class LoginComponent {

    public form: FormGroup;
  
    constructor(public fb: FormBuilder, 
                public router:Router,
                private sessionService:SessionService,
                private authService:AuthenticationService,
                private toasMsgService: ToasMsgService) {
        
        this.form = this.fb.group({
            'username': [null, Validators.compose([Validators.required])],
            'password': [null, Validators.compose([Validators.required])]
        });
        //Validators.minLength(6)
    }

    public onSubmit(values:Object):void {
        if (this.form.valid) {
            
            this.authService.authenticateService(this.form.value).subscribe(
                result => {
                  let resp = JSON.parse(JSON.stringify(result));
                  //console.log("resp: ", resp);
                  if(resp.success){
                    this.sessionService.setUsuario(JSON.stringify(resp.data));
                    this.router.navigate(['/welcome']);
                  }else{
                    this.toasMsgService.addToast('Advertencia', 'Las credenciales son incorrecta', 'error');
                  }
                },
                err => {
                  this.toasMsgService.addToast('Advertencia', 'Las credenciales ingresadas no son correctas', 'error');
                  console.error(err);
            });
                     
        }else{
            this.toasMsgService.addToast('Advertencia', 'Debe completar el formulario', 'wait');
        }

    }

    correoRestablecerContrasena(){
      swal({
        title: "Restablecer Contraseña",  
        text:"Favor ingrese su nombre de usuario, con el que accede a la plataforma. Se le enviará un mail con las instrucciones para restablecer su contraseña." ,
        input: "text",
        type: "info",   
        showCancelButton: true,    
        confirmButtonText: "Ok"
      }).then((confirm) => {
        if (confirm['value'] != null) {
          let usuario = {
            username: confirm['value']
          }

          this.authService.correoRestablecerContrasena(usuario).subscribe(
            result =>{
                let resp = JSON.parse(JSON.stringify(result));
                //console.log("resp: ", resp);
                if(resp.success){
                  
                  Swal.fire('Correo Enviado', resp.message, 'success')
                }else{
                  Swal.fire('Correo No Enviado', resp.message, 'warning')
                }
            });
        }
      });
    }

}
