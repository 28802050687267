import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../providers/session.service';

@Injectable()
export class TemplateService {

    public menuCentralPortal: boolean = false;
    public menuRecursosHumanos: boolean = false;
    public menuFinanzas: boolean = false;
    public menuBitacora: boolean = false;
    public menuGestionOt: boolean = false;
    private usuarioSelected: any;
    private moduloSelected: any;

    constructor(private http: HttpClient,
        private sessionService: SessionService) {
    }

    isSideNavCollapse: boolean = true;
    isSidePanelOpen: boolean = false;
    rtlActived: boolean = false;

    private isSideNavCollapseActived = new BehaviorSubject<boolean>(this.isSideNavCollapse);
    isSideNavCollapseChanges: Observable<boolean> = this.isSideNavCollapseActived.asObservable();

    private isSidePanelOpenActived = new BehaviorSubject<boolean>(this.isSidePanelOpen);
    isSidePanelOpenChanges: Observable<boolean> = this.isSidePanelOpenActived.asObservable();

    public toggleSideNavCollapse(isCollapse: boolean) {
        this.isSideNavCollapseActived.next(isCollapse);
    }

    public toggleSidePanelOpen(isOpen: boolean) {
        this.isSidePanelOpenActived.next(isOpen);
    }


    public cargarMenuByModulo(){
        this.moduloSelected = this.sessionService.getModulo();
        this.usuarioSelected = this.sessionService.getUsuario();
    
        if(this.moduloSelected.id == 1){

            this.menuRecursosHumanos = true;
            this.menuFinanzas = false;
            this.menuCentralPortal = false;
            this.menuBitacora = false;
            this.menuGestionOt=false;
            
        }else if(this.moduloSelected.id == 2){

            this.menuCentralPortal = false;
            this.menuRecursosHumanos = false;  
            this.menuFinanzas = true;    
            this.menuBitacora = false; 
            this.menuGestionOt=false;    
             
        }else if(this.moduloSelected.id == 3){

            this.menuCentralPortal = false;
            this.menuRecursosHumanos = false;  
            this.menuFinanzas = false;
            this.menuBitacora = true;
            this.menuGestionOt=false;

        }else if(this.moduloSelected.id == 4){

            this.menuGestionOt=true;
            this.menuCentralPortal = false;
            this.menuRecursosHumanos = false;  
            this.menuFinanzas = false;
            this.menuBitacora = false;

        }else{

          this.menuCentralPortal = true;
          this.menuRecursosHumanos = false;    
          this.menuFinanzas = false;  
          this.menuBitacora = false; 
          this.menuGestionOt=false;

        }

    } 


}