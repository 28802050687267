import { Component } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/providers/session.service';
import { ModuloService } from 'src/app/central-portal/services/modulo.service';

@Component({
    
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.css']
})
export class SidePanelComponent  {

    public isOpen: boolean
    public user;
    public listModulos: any[] = [];

    constructor(
        private tplSvc: TemplateService,
        public router: Router,
        private sessionService: SessionService,
        private moduloService: ModuloService) {
    }

    ngOnInit(): void {
        this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);
        this.user = this.sessionService.getUsuario();
        this.cargaListaModulos(this.user);
    }

    private cargaListaModulos(usuario) {
        this.moduloService.listModuloByUsuario(usuario).subscribe(
            (result) => {
                this.listModulos = result['data'];
                //this.listaUsuariosDataTable();
            }
        );
    }

    public selectModulo(modulo) {
        this.sessionService.setModulo(JSON.stringify(modulo));
        this.tplSvc.cargarMenuByModulo();
        this.toggleSidePanelOpen();
        this.router.navigate([modulo.routeLink]);
    }

    public toggleSidePanelOpen() {
        this.isOpen = !this.isOpen;
        this.tplSvc.toggleSidePanelOpen(this.isOpen);
    }
}
