import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2'
import { Denuncia } from '../model/denuncia.model';
import { DenunciasService } from '../services/denuncias.service';
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import { TIPOS_DENUNCIA } from '../services/constant.denuncias.service';

@Component ({
    templateUrl: 'steep3.html'
})

export class Steep3Component implements OnInit  {

    public esAnonimoList?: any[] =[];
    public identificadorDenuncia?: number;
    public adjuntoFormData?: null;
    public esAnonimoFrontValue?: boolean;


    formSteep3 = new FormGroup({
        esAnonimoController: new FormControl(''),
        nombreController: new FormControl(''),
        apellidoController: new FormControl(''),
        numeroDocumentoController: new FormControl(''),
        correoController: new FormControl(''),
    })
      

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private denunciaService: DenunciasService,
        private validarFormularioUtils: ValidarFormulariosUtils,
    ) { }

    ngOnInit() {
      if(this.canalDenunciasService.esAnonimoValue){
        this.esAnonimoFrontValue = true;
      }else{
        this.esAnonimoFrontValue = false;
      }

      if(this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.PREVENCION_DELITO && 
        (this.canalDenunciasService.itemDenuncia > 0 && this.canalDenunciasService.relacionDenuncia > 0)){

          $("#nombreController").attr('disabled', 'disabled');
          $("#apellidoController").attr('disabled', 'disabled');
          $("#numeroDocumentoController").attr('disabled', 'disabled');         
          $("#correoController").attr('disabled', 'disabled');
          
          this.cargaEsAnonimo();

      }else if(this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.LEY_KARIN && this.canalDenunciasService.itemDenuncia > 0 && this.canalDenunciasService.relacionDenuncia > 0){
        
        this.formSteep3.get("nombreController").reset();
        this.formSteep3.get("apellidoController").reset();
        this.formSteep3.get("numeroDocumentoController").reset();
        this.formSteep3.get("correoController").reset();

        $("#nombreController").removeAttr('disabled');
        $("#apellidoController").removeAttr('disabled');
        $("#numeroDocumentoController").removeAttr('disabled');
        $("#correoController").removeAttr('disabled');

        
      }else{
        this.router.navigateByUrl('canal-denuncias/undefined');
      }

       

    }

    cargaEsAnonimo() {
        this.esAnonimoList = [
          {id:'1', nombre:'SI'},
          {id:'2', nombre:'NO'}
        ];
    }

    radioChecked(id){
        this.canalDenunciasService.esAnonimo=id;
        //1 = Si
        //2 = No
        if(id== 2){
            this.formSteep3.get("nombreController").reset();
            this.formSteep3.get("apellidoController").reset();
            this.formSteep3.get("correoController").reset();
            this.formSteep3.get("numeroDocumentoController").reset();
            

            $("#nombreController").removeAttr('disabled');
            $("#apellidoController").removeAttr('disabled');
            $("#correoController").removeAttr('disabled');
            $("#numeroDocumentoController").removeAttr('disabled');
          }else if(id== 1){

            this.formSteep3.get("nombreController").reset();
            this.formSteep3.get("apellidoController").reset();
            this.formSteep3.get("correoController").reset();
            this.formSteep3.get("numeroDocumentoController").reset();

            $("#nombreController").attr('disabled', 'disabled');
            $("#apellidoController").attr('disabled', 'disabled');
            $("#correoController").attr('disabled', 'disabled');
            $("#numeroDocumentoController").attr('disabled', 'disabled');
          }else{

            this.formSteep3.get("nombreController").reset();
            this.formSteep3.get("apellidoController").reset();
            this.formSteep3.get("correoController").reset();
            this.formSteep3.get("numeroDocumentoController").reset();

            $("#nombreController").attr('disabled', 'disabled');
            $("#apellidoController").attr('disabled', 'disabled');
            $("#correoController").attr('disabled', 'disabled');
            $("#numeroDocumentoController").attr('disabled', 'disabled');
          }

        //array.forEach(item=>{
          //if(item.id !== id){ 
            // item.selected = false;
          //}else{
            // item.selected = true;
          //} 
        //})
    }

    public displayFieldCss(formControl: string) {
        let result: boolean;
    
        if (!this.formSteep3.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);   
            const retClass = result ? '' : 'invalid';   
            return retClass;
        }
    }

    public isFormControlValid(formControl: string) {
        const control = this.formSteep3.get(formControl);
    
        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }

    irAPrevencionDelitoSteep2(){
        swal({
            title: "¿Estás seguro que deseas volver atrás?",   
            text: "Se perderán todos los cambios!",   
            type: "warning",   
            showCancelButton: true, 
            cancelButtonText: "Cancelar",  
            confirmButtonColor: "#DD6B55",   
            confirmButtonText: "Si, deseo volver!"
          }).then((confirm) => {
            if (confirm['value']) {
                this.canalDenunciasService.volverSteep3=true;
                this.router.navigateByUrl('/canal-denuncias/steep2');
            } 
        });      
    }


    preRegistroDenuncia() { 
      console.log("preRegistroDenuncia: "+ this.esAnonimoFrontValue);
      if(this.esAnonimoFrontValue==false){
        const control = this.formSteep3.controls;
        this.canalDenunciasService.esAnonimo = "2"; //NO ANONIMO

        if(control['nombreController'].value ==null || control['apellidoController'].value ==null || 
            control['correoController'].value ==null || control['numeroDocumentoController'].value ==null || 
            control['nombreController'].value =='' || control['apellidoController'].value =='' || 
            control['correoController'].value =='' || control['numeroDocumentoController'].value ==''

          ){
          swal(
            'Error',
            'Favor complete sus datos de contacto.',
            'error'
          )
        }else{
          this.registroDenuncia();
        }

      }else{
        //evaluar control formulario
        const control = this.formSteep3.controls;
        if(control['esAnonimoController'].value == '1'){
          this.canalDenunciasService.esAnonimo = "1";
        }else if(control['esAnonimoController'].value == '2'){
          this.canalDenunciasService.esAnonimo = "2";
        }else{
          this.canalDenunciasService.esAnonimo = "1";
        }

        //Ir a registro denuncia
        this.registroDenuncia();
      }
    }


    registroDenuncia(){
      const control = this.formSteep3.controls;

      this.canalDenunciasService.nombreDenunciante= control['nombreController'].value;
      this.canalDenunciasService.apellidoDenunciante= control['apellidoController'].value;
      this.canalDenunciasService.correoDenunciante= control['correoController'].value;
      this.canalDenunciasService.numeroDocumento=  control['numeroDocumentoController'].value; 
      
      const denuncia: Denuncia = {
          id: this.identificadorDenuncia,
          tipoDenuncia: {id: this.canalDenunciasService.tipoDenuncia},
          item: {id: this.canalDenunciasService.itemDenuncia},
          tipoRelacion: {id: this.canalDenunciasService.relacionDenuncia},
          region: {id: this.canalDenunciasService.regionDenuncia},
          detalleRegion: this.canalDenunciasService.detalleRegionDenuncia,
          periodo: {id: this.canalDenunciasService.tiempoDenuncia},
          detalleInvolucrados: this.canalDenunciasService.detalleInvolucradosDenuncia,
          descripcionHechos: this.canalDenunciasService.descripcion,
          anonimo: this.canalDenunciasService.esAnonimo,
          nombreDenunciante: this.canalDenunciasService.nombreDenunciante,
          apellidoDenunciante: this.canalDenunciasService.apellidoDenunciante,
          correoDenunciante: this.canalDenunciasService.correoDenunciante,
          numeroDocumentoDenunciante: this.canalDenunciasService.numeroDocumento,
          activo: true
        };

  
      this.denunciaService.save(denuncia)
      .subscribe(
        (output: any[]) => {
          const success = output['success'];
          
          if (success) {

            this.canalDenunciasService.identificadorDenuncia = output['data']['id'];
            this.canalDenunciasService.codigoDenuncia= output['data']['codigoDenuncia'];
            this.canalDenunciasService.passwordDenunciante= output['data']['passwordDenuncia'];

            if(this.canalDenunciasService.formData !=null){                 
              this.denunciaService.uploadFile(this.canalDenunciasService.identificadorDenuncia,this.canalDenunciasService.formData).subscribe(
                resp => {
                  if(resp['success']){
                    this.router.navigateByUrl('/canal-denuncias/steep4');                    
                  }else{
                    swal(
                      'Error',
                      'El adjunto no ha podido ser cargado de forma exitosa. El registro se ha efectuado de todas formas.',
                      'error'
                    )
                    this.router.navigateByUrl('/canal-denuncias/steep4');                    
                  }
                },
                error => {
                  swal(
                    'Error',
                    'El adjunto no ha podido ser cargado de forma exitosa. El registro se ha efectuado de todas formas.',
                    'error'
                  )
                  this.router.navigateByUrl('/canal-denuncias/steep4'); 
                }
              );
            }
            else{
              this.router.navigateByUrl('/canal-denuncias/steep4');
              this.identificadorDenuncia =null;
            }

          } else {
            swal(
                'Error',
                'Se ha producido un error al intentar registrar la denuncia',
                'error'
              )
          }

        }
      );
    }

}