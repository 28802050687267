import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { DenunciasService } from '../services/denuncias.service';
import { ToastyUtils } from 'src/app/utils/toasty.utils';

@Component ({
    templateUrl: 'steep4.html'
})

export class Steep4Component implements OnInit  {

    public codigoDenuncia;
    public passDenuncia;
    public identificador;

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private denunciaService: DenunciasService,
        private toastyUtils: ToastyUtils,

    ) { }

    ngOnInit() {
        this.identificador = this.canalDenunciasService.identificadorDenuncia;
        this.codigoDenuncia = this.canalDenunciasService.codigoDenuncia;
        this.passDenuncia = this.canalDenunciasService.passwordDenunciante;

        //if(this.identificador == null){
            //this.router.navigateByUrl('/canal-denuncias');
        //}

       
        //set obj a null
        this.canalDenunciasService.canalDenuncias = null;
        this.canalDenunciasService.apellidoDenunciante = null; 
        this.canalDenunciasService.correoDenunciante = null;
        this.canalDenunciasService.descripcion = null;
        this.canalDenunciasService.detalleInvolucradosDenuncia = null;
        this.canalDenunciasService.detalleRegionDenuncia = null;
        this.canalDenunciasService.esAnonimo= null;
        this.canalDenunciasService.isAdmimnistrador= null;
        this.canalDenunciasService.isDenunciante=null;
        this.canalDenunciasService.itemDenuncia=null;
        this.canalDenunciasService.nombreDenunciante=null;
        this.canalDenunciasService.regionDenuncia=null;
        this.canalDenunciasService.relacionDenuncia=null;
        this.canalDenunciasService.tiempoDenuncia=null;
        this.canalDenunciasService.tipoDenuncia=null;
        this.canalDenunciasService.volverSteep1=null;
        this.canalDenunciasService.volverSteep2=null;
        this.canalDenunciasService.volverSteep3=null;

    }


    irAInicio() {
        this.canalDenunciasService.identificadorDenuncia=null;
        this.canalDenunciasService.codigoDenuncia = null;
        this.canalDenunciasService.passwordDenunciante=null;

        this.router.navigateByUrl('/canal-denuncias');
    }


    descargarPdf(){
        if(this.identificador!= null){
          const nameFile ='reporte_denuncia_'+this.codigoDenuncia+'.pdf';
          const result = this.denunciaService.exportarDenunciaPdf(this.identificador);
          result.subscribe(
              (success: any) => {
                  const blob = new Blob([success], {type: 'application/pdf'});
                  this.toastyUtils.addToast('Reporte PDF', 'El reporte ha sido generado', 'success');
                  if(window.navigator && window.navigator.msSaveOrOpenBlob){
                      window.navigator.msSaveOrOpenBlob(blob, nameFile);
                  }else{
                      const a = document.createElement('a');
                      a.href = URL.createObjectURL(blob);
                      a.download = nameFile;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                     
                  }
              },
              err => {
                  this.toastyUtils.addToast('Error', 'Ha ocurrido un error al intentar generar el reporte', 'error');
              }
          );
      
        }else{
          this.toastyUtils.addToast('Error', 'Ha ocurrido un error al intentar generar el reporte', 'error');
        } 
    }

}