import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { DenunciasService } from '../services/denuncias.service';
import { ToastyUtils } from 'src/app/utils/toasty.utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import { SeguimientoDenuncia } from '../model/seguimiento.denuncia.model';
import swal from 'sweetalert2'
import { LoginAdminDenuncia } from '../model/login.admin.denuncia.model';

@Component ({
    templateUrl: 'seguimiento.html'
})

export class SeguimientoComponent implements OnInit  {

    public codigoDenuncia;
    public passDenuncia;
    public identificador;

    formSeguimiento = new FormGroup({
        codigoController: new FormControl('', [Validators.required]),
        claveDenunciaController: new FormControl('', [Validators.required])
    })

    formLoginAdmin = new FormGroup({
        userController: new FormControl('', [Validators.required]),
        passwordController: new FormControl('', [Validators.required])
    })

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private denunciaService: DenunciasService,
        private toastyUtils: ToastyUtils,
        private validarFormularioUtils: ValidarFormulariosUtils,

    ) { }

    ngOnInit() {
        this.identificador = this.canalDenunciasService.identificadorDenuncia;
        this.codigoDenuncia = this.canalDenunciasService.codigoDenuncia;
        this.passDenuncia = this.canalDenunciasService.passwordDenunciante; 
    }

    irASeguimiento(){
        if(this.validarFormularioUtils.validarFormulario(this.formSeguimiento)){
            const control = this.formSeguimiento.controls;
            const seguimiento: SeguimientoDenuncia = {
                codigo: control['codigoController'].value,
                clave: control['claveDenunciaController'].value,
            }

            this.denunciaService.search(seguimiento)
            .subscribe(
                (output: any[]) => {
                const success = output['success'];
            
                    if (success) {
                        this.canalDenunciasService.identificadorDenuncia= output['data']['id'];
                        this.router.navigateByUrl('canal-denuncias/seguimiento/resultado');
                    } else {
                        swal(
                            'Error',
                            'El código y clave ingresado no coincide con ninguna denuncia registrada. Favor intentelo nuevamente.',
                            'error'
                        )
                    }

                }
            );
        }else{
            swal(
                'Error',
                'Debe ingresar un codigo y clave secreta',
                'error'
            )
        }
    }


    irAInicio() {
        console.log("origen canal denuncias: "+ this.canalDenunciasService.canalDenuncias);
        this.router.navigateByUrl('/canal-denuncias');
    }

    salir(){
        this.router.navigateByUrl('/canal-denuncias');
    }

    irAdministracion() {
        if(this.validarFormularioUtils.validarFormulario(this.formLoginAdmin)){
            const control = this.formLoginAdmin.controls;

            const login: LoginAdminDenuncia = {
                usuario: control['userController'].value,
                password: control['passwordController'].value,
            }

            this.denunciaService.loginAdmin(login)
            .subscribe(
                (output: any[]) => {
                const success = output['success'];
                    if (success) {
                        this.canalDenunciasService.isAdmimnistrador= true;
                        this.canalDenunciasService.tipoAdministrador= output['data']
                        $('#btn-cancelar-login').click();
                        this.router.navigateByUrl('canal-denuncias/administracion');
                    } else {
                        this.toastyUtils.addToast('Error', 'El usuario y password ingresado no coincide con ninguna usuario registrado. Favor intentelo nuevamente.', 'error');
                    }

                }
            );
        }else{
            this.toastyUtils.addToast('Error', 'Debe ingresar un usuario y contraseña', 'error');
        }
    }

    cancelarLogin(){
        this.formLoginAdmin.reset();
    }

}