import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { SessionService } from './session.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private sessionService: SessionService
  ){

  }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newContent;


        const user = this.sessionService.getUsuario() != null ? this.sessionService.getUsuario().traCodigo : '';

        if (req.headers.get('tipo') === null) {
          newContent = {
            //'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'VPSZiWHA6x' : user,          
            //'Authorization': 'Bearer '+ localStorage.getItem('token'),
          };
        }
        else {
          newContent = {
            //'Access-Control-Allow-Origin' : '*',
            'Accept': 'application/json',
            'VPSZiWHA6x' : user,           
            //'Authorization': 'Bearer '+ localStorage.getItem('token'),
            };
        }

        req = req.clone({
          setHeaders: newContent
        });
        
        return next.handle(req);
    }
}