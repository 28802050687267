import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import swal from 'sweetalert2';
import { ToastyUtils } from '../utils/toasty.utils';

@Injectable({
    providedIn: 'root'
})

export class HttpErrorService {

    constructor(private http: HttpClient,
      private toastyUtils: ToastyUtils) {

    }

    getMessageErrorServidor(message) {
      this.toastyUtils.addToast('Error', 'Se ha producido un error al intentar conectar con el servidor. Favor contáctese con el administrador.', 'error');
      /*swal(
        "Falló la  conexión con el servidor", "No se ha logrado establecer conexión con el servidor."+
         "Favor contáctese con el administrador." , "error"
      );*/
    }


}
