import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SessionService} from '../../../providers/session.service';
import {ToastOptions, ToastyConfig, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { RegionDenunciasService } from '../../services/region.denuncias.service';
import { Region } from '../../model/region.model';

@Component({
    selector: 'app-regiones',
    templateUrl: './regiones.component.html'
})
export class RegionesComponent implements OnInit {

    constructor(
        private sessionService: SessionService,
        private regionesService: RegionDenunciasService,
        private router: Router,
        private toastyService: ToastyService,

    ) {
       
    }

    formRegiones = new FormGroup({
        nombreRegionControl: new FormControl('', [Validators.required]),
        nombreCortoRegionControl: new FormControl('', null),
        activoRegionControl: new FormControl('',null) 
    });

    regiones: Region [] = [];
    codigoRegion;
    regionesSelected;
    tituloFormRegiones = 'Nuevo Registro';
    editRegionMode = false;
    public table;

    ngOnInit() {
 
      this.cargaRegiones();

    }


    refrescaRegionesModal(){
      this.codigoRegion =null;
      this.tituloFormRegiones = 'Nuevo Registro';
      this.editRegionMode = false;
      this.formRegiones.reset();
    
    }


    cargaRegiones() {

      this.regionesService.getListaRegiones()
        .subscribe(
          (regiones: any[]) => {
            this.regiones = regiones['data'];
            this.cargaTablaRegiones(this.regiones);
          }
        );
    }
  
    cargaTablaRegiones(regiones) {
      let numero = 0;
      this.table = $('#tableRegiones').DataTable({
        responsive: true,
        searching: true,
        data: regiones,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            width: "75%",
            title: 'Nombre', data: 'nombre'},
          {
            width: "20%",
            title: 'Estado', data: function (row) {
              if (row.activo) {
                return 'Activo';
              } else {
                return 'Inactivo';
              }
            }
          }
  
        ],
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.regionesSelected = data;
          });
  
          return row;
        }
      });    
    }

   

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formRegiones.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formRegiones.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formRegiones.controls).forEach(key => {
            this.formRegiones.get(key).markAsTouched();
            this.formRegiones.get(key).updateValueAndValidity();
            if (this.formRegiones.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarRegion() {
      let data = this.regionesSelected;
      this.codigoRegion = data['id'];
      $('#regionesModal').click();
      this.tituloFormRegiones = 'Editar Registro';
      this.editRegionMode = true;
      this.formRegiones.get('nombreRegionControl').setValue(data['nombre']);
      this.formRegiones.get('nombreCortoRegionControl').setValue(data['nombreCorto']);
      this.formRegiones.get('activoRegionControl').setValue(data['activo']); 
  
    }
    
    nuevoRegion() {

      const formValid = this.validarFormulario();
      if (formValid === true) {
  
        const control = this.formRegiones.controls;
        const regiones: Region = {
          id: this.codigoRegion,
          nombre: control['nombreRegionControl'].value,
          nombreCorto: control['nombreCortoRegionControl'].value,
          activo: true
        };
  
        let mensajeExito = '';
        let mensajeError = '';
  
        if (!this.editRegionMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.regionesService.setNuevoRegion(regiones)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoRegion = null;
                  this.regionesSelected = null;            
                  this.formRegiones.reset();
                  this.tituloFormRegiones = 'Nuevo Registro';
                  $('#btn-cerrar-modal-region').click();
                  $('#tableRegiones').DataTable().destroy();
                  this.cargaRegiones();
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } else {
          regiones.activo = control['activoRegionControl'].value;
          mensajeExito = 'Registro editado correctamente';
          mensajeError = 'Registro no pudo ser editado';
  
          this.regionesService.setNuevoRegion(regiones)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoRegion = null;
                  this.regionesSelected = null;         
                  this.formRegiones.reset();
                  this.tituloFormRegiones = 'Nuevo Registro';
                  $('#btn-cerrar-modal-region').click();
                  $('#tableRegiones').DataTable().destroy();
                  this.cargaRegiones();
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
            
        }
      }
    }
  
    deleteRegion(){
        swal({
          title: "Estás seguro que deseas eliminar el registro?",   
          text: "No podrás recuperar estos datos!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo eliminar!"
        }).then((confirm) => {
          if (confirm['value']) {
  
              /* eliminar registro */
              const id = this.regionesSelected['id'];
              this.regionesService.deleteRegion(id)
              .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    if (success) {
                        this.addToast('Éxito', 'Registro eliminado exitosamente', 'success');
                        this.editRegionMode=false;
                        this.regionesSelected=null;
                        this.codigoRegion=null;
                        $('#tableRegiones').DataTable().destroy();
                        this.cargaRegiones();                    
                    } else {
                        this.addToast('Error', 'No se pudo eliminar el registro', 'error');
                    }
                }
            );
          }
        });
    }

    volverAdministracion(){
      this.router.navigateByUrl('/canal-denuncias/administracion');
    }
}
