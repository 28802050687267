import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthenticationService} from './auth.service';
import {environment} from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {
    env = environment;
    ruta;

    constructor(private router: Router, 
        private sessionService: SessionService,
        private authService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log("canActivate");
        if (this.sessionService.isUserLoggedIn()) {
            return true;
        }

        this.router.navigate(['login']);
        return false;

    }

    canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log("canLoad");
        if (this.sessionService.isUserLoggedIn()) {
            return true;
        }

        this.router.navigate(['login']);
        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log("canActivateChild");
        this.ruta = state.url;
    
        if (this.sessionService.isUserLoggedIn()) {
            return true;
        }

        /*if (this.authService.isUserLoggedIn()) {
            if (this.ruta === '/inventario/ingreso' && this.env.isUser) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta === '/inventario/asignar' && this.env.isUser) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/marca' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/modelo' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/tipo' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/subTipo' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/proveedor' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/proveedorContacto' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }

            if (this.ruta == '/inventario/perfil' && !this.env.isAdmin) {
                this.router.navigate(['/inventario/stock']);
                return false;
            }
            //this.authService.cargaRoles();

            return true;
        }*/

        this.router.navigate(['login']);
        return false;
    }
}
