import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ModuloService {

  private apiURL = environment.apiCentralPortal;
  private services = "/modulo";

  constructor(private http: HttpClient) {
  }


  public listModulo() : Observable<any> {
    return this.http.get<any>(this.apiURL + '/modulo/list');
  }

  public listModuloByUsuario(usuario) : Observable<any> {
    return this.http.post<any[]>(this.apiURL + '/modulo/listByUsuario', usuario);
  }

  public listPerfilByModulo(modulo) : Observable<any> {
    return this.http.post<any>(this.apiURL +'/modulo/listPerfilByModulo', modulo);
  }

  public listPerfilByUsuarioAndModuloAndActivo(obj) : Observable<any> {
    return this.http.post<any>(this.apiURL +'/modulo/listPerfilByUsuarioAndModuloAndActivo', obj);
  }

  public listUsuModPerByUsuarioAndModulo(obj) : Observable<any> {
    return this.http.post<any>(this.apiURL +'/modulo/listUsuModPerByModuloAndUsuario', obj);
  }

  public saveUsuarioModuloPerfil(obj) : Observable<any> {
    return this.http.post<any>(this.apiURL +'/modulo/saveUsuarioModuloPerfil', obj);
  }


}
