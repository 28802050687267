import {Component, OnInit} from '@angular/core';
import { SessionService } from './providers/session.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {


    constructor(private sessionService: SessionService) {
    }


    ngOnInit() {

      //  console.log('INIT');

    }

}


