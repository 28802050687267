import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { ModuloService } from '../central-portal/services/modulo.service';
import {MODULOS_VALUES, PERFILES_VALUES_GESTION} from '../constants/proterm.modulos';
import { PERFILES_VALUES_RRHH } from '../constants/proterm.modulos';
import { PERFILES_VALUES_FINANZAS } from '../constants/proterm.modulos';
import { PERFILES_VALUES_BITACORA } from '../constants/proterm.modulos';
  import { from } from 'rxjs';
import { TrabajadorService } from '../services/Trabajador.service';
import { Trabajador } from '../models/Trabajador.model';

const usuarioKEY = '_uoj';
const moduloKEY = '_moj';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    public usuarioDependencia: number;
    public usuarioGerencia: number;
    public usuarioNombre: string;
    public avisoNuevaSolicitud = true;
    public listaPerfiles: any[] = [];

    //usuario login
    public trabajadorLogin : Trabajador[]=[];
    public traCodigoLogin;
    public nombreCompletoLogin;
    public codigoGerenciaLogin;
    public nombreGerenciaLogin;
    public codigoAreaLogin;
    public nombreAreaLogin;


    //perfiles global 
    public isAdmin;
    //perfiles RRHH 
    public isAsistente;
    public isAsist;
    public isGerente;
    public isJefeArea;
    public isJefeProyecto;
    public isIngeniero;
    public acceso=false;

    //perfiles finanzas
    public isSolicitante;
    public isAprobador;

    //perfiles bitacora
    public isAdmVehiculos;
    public isAdmEquipos;
    public isSolicitanteVehiculos;
    public isSolicitanteEquipos;

    constructor(
      private moduleService: ModuloService,
      private trabajadorService: TrabajadorService
    ) {
    }  
    


    public isUserLoggedIn() {
        const user = localStorage.getItem(usuarioKEY);
        return (user !== null);
    }

    public setUsuario(obj: any) {    
        localStorage.removeItem(usuarioKEY);
        localStorage.setItem(usuarioKEY, obj);
    }

    public getUsuario(): any {
        let obj = JSON.parse(localStorage.getItem(usuarioKEY));    
        return obj;
    }

    public setModulo(obj: any) {    
        localStorage.removeItem(moduloKEY);
        localStorage.setItem(moduloKEY, obj);
    }

    public getModulo(): any {
        let obj = JSON.parse(localStorage.getItem(moduloKEY));    
        return obj;
    }

    public logOut() {
        localStorage.clear();
    }


    public cargaPerfilesUsuarioGlobal(){

      let usurioModuloPerfil = {
          'usuarioId' : this.getUsuario().id,
          'moduloId' : this.getModulo().id,
      }

      this.moduleService.listPerfilByUsuarioAndModuloAndActivo(usurioModuloPerfil).subscribe(
          (result)=>{
              this.listaPerfiles = result['data'];
              this.setPerfilModuloUsuario(this.listaPerfiles);
          }
      );
    }

    public setPerfilModuloUsuario(listaPerfiles){

      let moduloId = this.getModulo().id;

      if(moduloId == MODULOS_VALUES.RRHH){   
        
        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.ADMIN)){
            this.isAdmin = true;  
        }else{
          this.isAdmin = false;
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.ASISADM)){
            this.isAsist = true;
        }else{
          this.isAsist = false;
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.GERENTE)){
            this.isGerente = true;
        }else{
          this.isGerente = false;
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.JEFEAREA)){
            this.isJefeArea = true;
        }else{
          this.isJefeArea = false;
        }

        if(this.isAdmin || this.isAsist || this.isGerente || this.isJefeArea){
          this.acceso=true;
          return this.acceso;
        }


      }else if(moduloId == MODULOS_VALUES.FINANZAS){

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.ADMIN)){
            this.isAdmin = true; 
        }else{
          this.isAdmin = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_FINANZAS.SOLICITANTE)){
            this.isSolicitante = true; 
        }else{
          this.isSolicitante = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_FINANZAS.APROBADOR)){
            this.isAprobador = true; 
        }else{
          this.isAprobador = false; 
        }

        if(this.isAdmin){
          this.acceso =true;
        }else{
          this.acceso =false;
        }

      }else if(moduloId == MODULOS_VALUES.BITACORA){

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.ADMIN)){
            this.isAdmin = true; 
        }else{
          this.isAdmin = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_BITACORA.ADMINISTRADOR_VEHICULOS)){
            this.isAdmVehiculos = true; 
        }else{
          this.isAdmVehiculos = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_BITACORA.ADMINISTRADOR_EQUIPOS)){
            this.isAdmEquipos = true; 
        }else{
          this.isAdmEquipos = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_BITACORA.SOLICITANTE_VEHICULOS)){
            this.isSolicitanteVehiculos = true; 
        }else{
          this.isSolicitanteVehiculos = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_BITACORA.SOLICITANTE_EQUIPOS)){
            this.isSolicitanteEquipos = true; 
        }else{
          this.isSolicitanteEquipos = false; 
        }

        if(this.isAdmin || this.isAdmVehiculos){
          this.acceso=true;
          return this.acceso;
        }else{
          this.acceso=false;
          return this.acceso;
        }



      }else if(moduloId == MODULOS_VALUES.GESTIONOT){
        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_RRHH.ADMIN)){
            this.isAdmin = true; 
        }else{
          this.isAdmin = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_GESTION.GERENTE)){
            this.isGerente = true; 
        }else{
          this.isGerente = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_GESTION.JEFE_PROYECTO)){
            this.isJefeProyecto = true; 
        }else{
          this.isJefeProyecto = false; 
        }

        if(listaPerfiles.find(
          perfilUsuario => perfilUsuario.id === PERFILES_VALUES_GESTION.INGENIERO)){
            this.isIngeniero = true; 
        }else{
          this.isIngeniero = false; 
        }
      }
       
      return this.acceso;

    }


    public obtenerTrabajadorLogin(){

      this.trabajadorService.getTrabajador(this.getUsuario().traCodigo)
      .subscribe(
        (trabajador: Trabajador[]) => {

          this.trabajadorLogin = trabajador['data'];
          this.traCodigoLogin = this.trabajadorLogin['id'];;
          this.nombreCompletoLogin = this.trabajadorLogin['nombreCompleto'];
          this.codigoGerenciaLogin = this.trabajadorLogin['gerencia'].id;
          this.nombreGerenciaLogin = this.trabajadorLogin['gerencia'].nombre;
          this.codigoAreaLogin = this.trabajadorLogin['dependencia'].id;
          this.nombreAreaLogin = this.trabajadorLogin['dependencia'].nombre;

        }
      );
    }



}

