import { Component } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import {SessionService} from "../../providers/session.service";
import { ModuloService } from 'src/app/central-portal/services/modulo.service';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html'
})
export class SideNavComponent  {

    public isCollapse: boolean;

    public listaPerfiles: any[] = [];
    private usuarioSelected: any;
    private moduloSelected: any;
    public acceso=false;
    public accesoOrdenesTrabajo=false;
    

    constructor(
        public tplSvc: TemplateService, 
        private router: Router, 
        private moduloService: ModuloService,
        private sessionService: SessionService) {

        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (window.innerWidth < 992) {
                    this.tplSvc.toggleSideNavCollapse(false);
                } 
            }
        })
    }

    async ngOnInit() {
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
        this.tplSvc.cargarMenuByModulo();
        this.cargaPerfilesUsuario();
        this.sessionService.cargaPerfilesUsuarioGlobal();
        this.sessionService.obtenerTrabajadorLogin();
    }    

    private cargaPerfilesUsuario(){
        let usurioModuloPerfil = {
            'usuarioId' : this.sessionService.getUsuario().id,
            'moduloId' : this.sessionService.getModulo().id,
        }
        this.moduloService.listPerfilByUsuarioAndModuloAndActivo(usurioModuloPerfil).subscribe(
            (result)=>{
                this.listaPerfiles = result['data'];
                this.permisoRecursosHumanos('');
            }
        );
    }

    permisoRecursosHumanos(menu){
        let administrador = 1;
        let usuarioBasico = 2;
        let asistenteAdministrativo = 3;
        let gerente =4;
        let jefeArea =7;
        let aprobadorOc= 6;

        const isAdmin = this.listaPerfiles.find(
            perfilUsuario => perfilUsuario.id === administrador );
        
        const isAsist = this.listaPerfiles.find(
                perfilUsuario => perfilUsuario.id === asistenteAdministrativo );

        const isGerente = this.listaPerfiles.find(
                perfilUsuario => perfilUsuario.id === gerente );

        const isJefeArea = this.listaPerfiles.find(
                perfilUsuario => perfilUsuario.id === jefeArea );
         
        const isAprobadorOc = this.listaPerfiles.find(
                  perfilUsuario => perfilUsuario.id === aprobadorOc );
        
        if(isAdmin || isAsist || isGerente || isJefeArea || isAprobadorOc){
          this.acceso=true;
         // return this.acceso;
        }

        

        
      //return this.acceso;        
    }

    /*this.listaPerfiles.filterforEach(item =>{
            console.log(item.nombre);
            if(item.id == administrador){
                console.log()
                return true
            }else if(item.id == usuarioBasico){

            }else if(item.id == asistenteAdministrativo){Ç

            }else{

            }
        });*/

    public toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }


    public abrirMenuItem(menuId){
        let menuItem = document.getElementById('menu-item-'+menuId);
        let subMenu = document.getElementById('sub-menu-'+menuId);  

       // console.log("subMenu : " + subMenu);

        if(subMenu){

          if(subMenu.classList.contains('show')){
            subMenu.classList.remove('show');
            menuItem.classList.remove('open');
          }
          else{
            subMenu.classList.add('show');
            menuItem.classList.add('open');
          }      
        }
    }


    toggleMenu(event) {
      const menuSeleccionado = event.target;
  
      if (menuSeleccionado.classList.contains('dropdown-toggle')) {
        const menuPadre = menuSeleccionado.closest('li.nav-item.dropdown');
        menuPadre.classList.toggle('open');
  
        this.toggleMostrarHijo(menuPadre);
      } else {
        const menuPadreSinHijos = menuSeleccionado.closest('li.nav-item');
        this.ocultarTodosLosDemas(menuPadreSinHijos.id);
      }
    }
  
    toggleMostrarHijo(menuPadre) {
      const menusHijos: HTMLElement[] = Array.from(menuPadre.childNodes);
      const menuColapsable: HTMLElement = menusHijos.find((nodo: HTMLElement) => nodo.classList.contains('dropdown-menu'));
  
      if (menuColapsable) {
  
        if (menuPadre.classList.contains('open')) {
          if (!menuPadre.classList.contains('submenu')) {
            this.ocultarTodosLosDemas(menuPadre.id);
          }
          menuColapsable.style.display = 'block';
        } else {
          menuColapsable.style.display = 'none';
        }
  
      }
  
    }
  
    ocultarTodosLosDemas(id) {
      const contenedorMenu = document.getElementsByClassName('side-nav-menu')[0];
      const menus: ChildNode[] = Array.from(contenedorMenu.childNodes);
  
      menus.map((nodo: HTMLElement) => {
        if (nodo.classList && nodo.classList.contains('nav-item') && nodo.classList.contains('open') && nodo.id !== id) {
          nodo.classList.remove('open');
          this.toggleMostrarHijo(nodo);
        }
      });
    }
}
