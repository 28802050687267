import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import { SessionService } from './session.service';
import { HttpErrorService } from '../services/http.error.service';


@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

 //constructor(){}

  constructor(private httpErrorService: HttpErrorService)  {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler):  Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }

        // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
        /* si es error de conexion con API; mostrar alerta */
        if(error.status == 0){
          this.httpErrorService.getMessageErrorServidor(errorMessage);
        }

        return throwError(errorMessage);
      })
    );
  }

}