import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Trabajador} from '../models/Trabajador.model';
import { RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class TrabajadorService {

  apiURL = environment.apiProterm;

  public editTrabajador: Trabajador;

  constructor(private http: HttpClient) {

  }


  public getListaTrabajadoresInitializable(urlFilter) {
    return this.http.get(this.apiURL + 'trabajadores/initializable/' + urlFilter);
  }

  public getListaTrabajadoresPrincipal(urlFilter) {
    return this.http.get(this.apiURL + 'trabajadores/listprincipal/' + urlFilter);
  }


  public getListaTrabajadores(urlFilter) {
    return this.http.get(this.apiURL + 'trabajadores/listbyfilter' + urlFilter);
  }

  public getTrabajador(trabajador) {
    return this.http.get(this.apiURL + 'trabajadores/get/' + trabajador);
  }

  public getTrabajadorByRut(newRut) {
    return this.http.get(this.apiURL + 'trabajadores/getbyrut/' + newRut);
  }

  public validaExistenciaIniciales(newIniciales) {
    return this.http.get(this.apiURL + 'trabajadores/validaexistenciainiciales/' + newIniciales);
  }

  public setNuevoTrabajador(trabajador) {
    return this.http.post(`${this.apiURL}trabajadores/save/`, trabajador);
  }

  public updateTrabajador(newTrabajador) {
    return this.http.put(`${this.apiURL}trabajadores/` + newTrabajador.id, newTrabajador);
  }

  public updateDatosPersonalesTrabajador(newTrabajador) {
    return this.http.post(`${this.apiURL}trabajadores/updatedatospersonales`, newTrabajador);
  }

  public updateDatosLaboralesTrabajador(newTrabajador) {
    return this.http.post(`${this.apiURL}trabajadores/updatedatoslaborales`, newTrabajador);
  }

  public updateContactoEmergencia(contactoEmergencia) {
    return this.http.post(`${this.apiURL}trabajadores/updatecontactoemergencia`, contactoEmergencia);
  }

  /*public obtieneTrabajadoresGerencia(gerencia) {
    return this.http.get(this.apiURL + 'trabajadores/activosporroleoespecialidadodependenciaygerencia?gerencia=' + gerencia);
  }*/

  public obtieneTrabajadoresGerenciaPorUsuarioDependencia() {
    return this.http.get(this.apiURL + 'trabajadores/listbygerencia');
  }

  public desactivarTrabajador(id) {
    return this.http.delete(`${this.apiURL}trabajadores/desactivar/` + id);
  }

  public activarTrabajador(id) {
    return this.http.delete(`${this.apiURL}trabajadores/activar/`+ id);
  }

  public subirImagen(traCodigo, formData) {
    return this.http.post(this.apiURL + 'trabajadores/subirimagen/' + traCodigo ,formData,{
      headers: {
        'tipo': 'archivo'
      }
    });
  }

  public getImagen(traCodigo) {
    return this.http.get<any>(this.apiURL + 'trabajadores/getimagen/' + traCodigo) 
  } 

  public uploadimagen(formData) {
    return this.http.post(this.apiURL + 'trabajadores/uploadimagen/', formData,{
      headers: {
        'tipo': 'archivo'
      }
    });
  }

  public getExportarExcel(urlFilter){
    return this.http.post(this.apiURL + 'trabajadores/exportar_listado'+ urlFilter , {}, {responseType: 'blob'});
  }


}
