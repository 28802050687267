import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TipoRelacionDenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public isDenunciante: Boolean;


    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
    }

    public getListaTipoRelacion() {
      return this.http.get(this.apiURL + 'tiporelacion/list');
    }
    
    public getListaTipoRelacionByTipo(tipoDenuncia){
      return this.http.get(this.apiURL + 'tiporelacion/listByTipoDenuncia/' + tipoDenuncia);
    }

    public setNuevoTipoRelacion(tiporelacion) {
      return this.http.post(`${this.apiURL}tiporelacion/save`, tiporelacion);
    }
  
    public deleteTipoRelacion(tiporelacion) {
      return this.http.delete(this.apiURL + 'tiporelacion/delete/' + tiporelacion);
    }
}
