import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { Denuncia } from '../model/denuncia.model';

@Injectable({
    providedIn: 'root'
})

export class DenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public isDenunciante: Boolean;
    public verdenuncia: Denuncia;

    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
    }

    public get(id) {
      return this.http.get(this.apiURL + 'denuncia/get/'+ id);
    }

    public getListaDenuncias() {
      return this.http.get(this.apiURL + 'denuncia/list');
    }

    public getListaDenunciasByFilter(urlFilter) {
      return this.http.get(this.apiURL + 'denuncia/listByFilter/'+ urlFilter);
    }
    
    public save(denuncia) {
      return this.http.post(`${this.apiURL}denuncia/save`, denuncia);
    }

    public update(denuncia) {
      return this.http.post(`${this.apiURL}denuncia/update`, denuncia);
    }

    public search(seguimiento) {
      return this.http.post(`${this.apiURL}denuncia/search`, seguimiento);
    }

    public exportarDenunciaPdf(id){
      return this.http.post(this.apiURL + 'denuncia/pdfreportdenuncia/'+ id , {}, {responseType: 'blob'});
    }

    public uploadFile(codigoDenuncia, formData) {
      return this.http.post(this.apiURL + 'historialseguimiento/uploadfile/' + codigoDenuncia ,formData,{
        headers: {
          'tipo': 'archivo'
        }
      });
    }

    public getListaComentarios(id) {
      return this.http.get(this.apiURL + 'historialseguimiento/list/'+id);
    }

    public getDocumento(id){
      return this.http.get(this.apiURL + 'historialseguimiento/getdocumento/'+ id , {responseType: 'blob'});
    }

    public nuevoComentarioAdjunto(idComentario, formData) {
      return this.http.post(this.apiURL + 'historialseguimiento/nuevoComentarioAdjunto/' + idComentario ,formData,{
        headers: {
          'tipo': 'archivo'
        }
      });
    }

    public saveComentario(comentario) {
      return this.http.post(`${this.apiURL}historialseguimiento/save`, comentario);
    }

    public loginAdmin(login) {
      return this.http.post(`${this.apiURL}administracion/login`, login);
    }
}
