import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { ToastOptions, ToastyService } from 'ng2-toasty';

@Injectable({
    providedIn: 'root'
})

export class ToasMsgService {

    public usuarioDependencia: number;
    public usuarioGerencia: number;
    public usuarioNombre: string;
    public avisoNuevaSolicitud = true;

    constructor(
        private toastyService: ToastyService
    ) {
        
    }

    public addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }

}

