import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    
    private APIsecurity;
    private services = '/usuario';
     private urlFilter ='';

    constructor(private httpClient: HttpClient, private router: Router) {
        this.APIsecurity = environment.apiCentralPortal+this.services;
    }

    public authenticateService(obj) {
        return this.httpClient.post(this.APIsecurity+'/login',obj);
    }

    public authenticateJwtService(username, password) {
        const loginData = {"user": username, "pwd": password}
        let api = 'http://localhost:8085/apirest-integracion-sma/integracion/user';
        this.urlFilter = '?';
        this.urlFilter += 'user=' + username + '&';
        this.urlFilter += 'password=' + password + '&';
        this.urlFilter = this.urlFilter.substring(0, this.urlFilter.length - 1);     
        //const apiUrl = 'http://localhost:8085/apirest-integracion-sma/integracion/user/';
        //const apiUrl = 'http://localhost:8085/apirest-integracion-sma/integracion/test';

        console.log(loginData);
        return new Promise((resolve, reject) => {
    //  var algo =this.httpClient.post(api+'integracion/user', this.urlFilter).subscribe(
        var algo = this.httpClient.post(api,loginData).subscribe(
            (val) => {
                localStorage.setItem('username', username)
                localStorage.setItem('token', val['token'])
                this.router.navigate(['/welcome']);
                resolve (true);
            },
            response => {
              console.log("POST call in error", response);
              reject (response);
            }
          );
        });

    }

    public correoRestablecerContrasena(obj) {
        return this.httpClient.post(environment.apiCentralPortal+'/restablecercontrasena/',obj);
    }

    public restablecerContrasena(obj) {
        return this.httpClient.post(environment.apiCentralPortal+'/restablecercontrasena/formulario/',obj);
    }

    
    /*cargaRoles() {
        const jwt = sessionStorage.getItem('token');
        const jwtData = jwt.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const roles = JSON.parse(decodedJwtJsonData);
        const datosRole = JSON.parse(roles.authorities);
        const length = datosRole.length;

        for (let i = 0; i < length; i++) {
            if (datosRole[i].authority === 'ROLE_ADMIN') {
                environment.isAdmin = true;
            } else if (datosRole[i].authority === 'ROLE_USER') {
                environment.isUser = true;
            } else if (datosRole[i].authority === 'ROLE_SUPPORT') {
                environment.isSupport = true;
            }
        }
    }*/

/*      const token = sessionStorage.token;
        const jwt = token;
        const jwtData = jwt.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const detalleTrabajador = JSON.parse(decodedJwtJsonData);

        this.sessionService.usuarioNombre = detalleTrabajador.detalleTrabajador.nombreCompleto;
        this.sessionService.usuarioDependencia = detalleTrabajador.detalleTrabajador.dependenciaCodigo;
        this.sessionService.usuarioGerencia = detalleTrabajador.detalleTrabajador.gerenciaCodigo; 
    */

}
