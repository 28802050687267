import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class EstadoDenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public isDenunciante: Boolean;


    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
    }

    public getListaEstadoDenuncias() {
      return this.http.get(this.apiURL + 'estadodenuncia/list');
    } 

    public setNuevoEstadoDenuncia(estadoDenuncia) {
      return this.http.post(`${this.apiURL}estadodenuncia/save`, estadoDenuncia);
    }
  
    public deleteEstadoDenuncia(estadoDenunciaId) {
      return this.http.delete(this.apiURL + 'estadodenuncia/delete/' + estadoDenunciaId);
    }
}
