import {Routes} from '@angular/router';
// Layouts
import {TemplateComponent} from './template/template.component';
import {Page404Component} from './common/errors/404/404.component';
import {Page500Component} from './common/errors/500/500.component';
import {AuthComponent} from './common/auth/auth.component';
import {AuthenticationGuardService} from './providers/auth.guard.service';
import { LoginComponent } from './common/login/login.component';
import { WelcomeComponent } from './common/welcome/welcome.component';
import { MiPerfilComponent } from './mi-perfil/mi.perfil.component';
import { RestablecerContrasenaComponent } from './common/restablecer-contrasena/restablecer.contrasena.component';
import { InicioComponent } from './canal-denuncias/inicio/inicio.component';
import { Steep1Component } from './canal-denuncias/steep1/steep1.component';
import { Steep2Component } from './canal-denuncias/steep2/steep2.component';
import { Steep3Component } from './canal-denuncias/steep3/steep3.component';
import { Steep4Component } from './canal-denuncias/steep4/steep4.component';
import { UndefinedComponent } from './canal-denuncias/undefined/undefined.component';
import { SeguimientoComponent } from './canal-denuncias/seguimiento/seguimiento.component';
import { ResultadoDenunciaComponent } from './canal-denuncias/seguimiento/resultado/resultado.denuncia.component';
import { EstadoDenunciaComponent } from './canal-denuncias/administracion/estadodenuncias/estadodenuncia.component';
import { TiempoTranscurridoComponent } from './canal-denuncias/administracion/periodicidades/tiempo.transcurrido.component';
import { TipoDenunciaComponent } from './canal-denuncias/administracion/tipodenuncias/tipodenuncia.component';
import { TipoRelacionComponent } from './canal-denuncias/administracion/tiporelacion/tiporelacion.component';
import { RegionesComponent } from './canal-denuncias/administracion/regiones/regiones.component';
import { AdministracionCanalDenunciasComponent } from './canal-denuncias/administracion/administracion.component';
import { DelitosComponent } from './canal-denuncias/administracion/delitos/delitos.component';
import { DenunciasComponent } from './canal-denuncias/administracion/denuncias/denuncias.component';

// canLoad: [AuthenticationGuardService]}

export const AppRoutes: Routes = [
    {path: '', redirectTo: 'miperfil', pathMatch: 'full' },
    {path: '', component: TemplateComponent, canActivateChild: [AuthenticationGuardService], children: [
            {path: 'centralportal', loadChildren: './central-portal/central.portal.module#CentralPortalModule'},
            {path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
            {path: 'miperfil', component: MiPerfilComponent },
            {path: 'recursoshumanos', loadChildren: './recursos-humanos/recursos.humanos.modules#RecursosHumanosModule'},
            {path: 'finanzas', loadChildren: './finanzas/finanzas.module#FinanzasModule'},
            {path: 'bitacora', loadChildren: './bitacora/bitacora.module#BitacoraModule'},
            {path: 'gestion', loadChildren: './gestion/gestion.module#GestionModule'},
            {path: 'extras', loadChildren: './tool-box-theme/extras/extras.modules#ExtrasModule'}         
        ]
    },
    { path: 'auth/:token', component: AuthComponent },
    { path: 'login', component: LoginComponent, data: {title: 'Login'} },
    { path: 'restablecercontrasena/formulario/:id', component: RestablecerContrasenaComponent, data: {title: 'Restablecer Contraseña'} },
    { path: 'welcome', component: WelcomeComponent, data: {title: 'Bienvenido'}, canActivate: [AuthenticationGuardService] },
    { path: 'error', component: Page500Component, data: {title: '500'} },
    { path: 'canal-denuncias', component: InicioComponent, data: {title: 'Canal de Denuncias'} },
    { path: 'canal-denuncias/steep1', component: Steep1Component, data: {title: 'Steep 1'} },
    { path: 'canal-denuncias/steep2', component: Steep2Component, data: {title: 'Steep 2'} },
    { path: 'canal-denuncias/steep3', component: Steep3Component, data: {title: 'Steep 3'} },
    { path: 'canal-denuncias/steep4', component: Steep4Component, data: {title: 'Steep 4'} },
    { path: 'canal-denuncias/seguimiento', component: SeguimientoComponent, data: {title: 'Seguimiento'} },
    { path: 'canal-denuncias/seguimiento/resultado', component: ResultadoDenunciaComponent, data: {title: 'Resultado Seguimiento'} },
    { path: 'canal-denuncias/administracion/estadodenuncias', component: EstadoDenunciaComponent, data: {title: 'Estado Denuncia'} },
    { path: 'canal-denuncias/administracion/periodicidades', component: TiempoTranscurridoComponent, data: {title: 'Tiempo Transcurrido'} },
    { path: 'canal-denuncias/administracion/tipodenuncias', component: TipoDenunciaComponent, data: {title: 'Tipo Denuncia'} },
    { path: 'canal-denuncias/administracion/tiporelacion', component: TipoRelacionComponent, data: {title: 'Tipo Relación'} },
    { path: 'canal-denuncias/administracion/regiones', component: RegionesComponent, data: {title: 'Regiones'} },
    { path: 'canal-denuncias/administracion', component: AdministracionCanalDenunciasComponent, data: {title: 'Administración'} },
    { path: 'canal-denuncias/administracion/delitos', component: DelitosComponent, data: {title: 'Delitos'} },
    { path: 'canal-denuncias/administracion/denuncias', component: DenunciasComponent, data: {title:'Denuncias'} },
    { path: 'canal-denuncias/undefined', component: UndefinedComponent, data: {title: 'Undefined'} },
    { path: '**', component: Page404Component, data: {title: '404'} } ,
    
];

