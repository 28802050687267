import { Component } from '@angular/core';
import { SessionService } from '../providers/session.service';
import { AuthenticationService } from '../providers/auth.service';
import { TrabajadorService } from '../services/Trabajador.service';
import { Trabajador } from '../models/Trabajador.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component ({
    selector: 'app-mi-perfil',
    templateUrl: 'mi.perfil.component.html'
})

export class MiPerfilComponent {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private trabajadorService: TrabajadorService
        ) { }

    apiURL = environment.apiProterm;
    public traCodigo: number;
    public nombreTrabajador : string;
    public cargo: string;
    public emailCoporporativo: string;
    public area: string;
    public estado: string;
    public trabajador: Trabajador;
    public existeImagen;
    public obtenerImagenPerfil;
    public gerencia: string;      

    ngOnInit() {

        this.traCodigo = this.sessionService.getUsuario().traCodigo;
        this.getTrabajadorById(this.traCodigo);
    }
    
    
    getTrabajadorById(traCodigo){
        this.trabajadorService.getTrabajador(traCodigo)
        .subscribe(
          (trabajador: Trabajador[]) => {         
            this.trabajador = trabajador['data'];
            this.cargaVistaMiPerfil(this.trabajador);
            
          }
        );
    }

    cargaVistaMiPerfil(data){
        this.nombreTrabajador = data['nombreCompleto'];

        
        if(data['cargo'].id==null){this.cargo = 'No Definido'}else{this.cargo =data['cargo'].nombre}
        if(data['dependencia'].id==null){this.area = 'No Definido'}else{this.area =data['dependencia'].nombre}
        if(data['gerencia'].id==null){this.gerencia = 'No Definido'}else{this.gerencia =data['gerencia'].nombre}

        if(data['activo']){this.estado = 'Activo'}else{this.estado ='Inactivo'}


        this.existeImagen = data['contieneImagen'];

        if(this.existeImagen){
        this.obtenerImagenPerfil = this.apiURL+'trabajadores/getimagen/'+this.traCodigo;
        }else{
        this.obtenerImagenPerfil = 'assets/images/others/user_perfil.jpg';
        }
            
    }


    obtenerFichaTrabajador() {
        const editarTrabajador = this.trabajador;

        this.trabajadorService.editTrabajador = editarTrabajador;
        this.router.navigateByUrl('/recursoshumanos/ficha');
    }

}