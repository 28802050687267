import {Injectable} from '@angular/core';
import {date} from "ng2-validation/dist/date";
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})

export class TimeUtils {
    
    
    constructor(
        private datePipe: DatePipe
    ) {
    }
    
    public fechaObjToString(fechaObj) {
        if (fechaObj != null && fechaObj !== '') {
            if (parseInt(fechaObj.day, 10) < 10) {
                fechaObj.day = '0' + parseInt(fechaObj.day, 10);
            }            
            if (parseInt(fechaObj.month, 10) < 10) {
                fechaObj.month = '0' + fechaObj.month;
            }
            return fechaObj.year + '-' + fechaObj.month + '-' + fechaObj.day;
        } else {
            return '';
        }
    }
    
    public fechaObjToStringDMY(fechaObj) {
        
        if (fechaObj != null && fechaObj !== '') {
            
            if (parseInt(fechaObj.day, 10) < 10) {
                fechaObj.day = '0' + parseInt(fechaObj.day, 10);
            }
            
            if (parseInt(fechaObj.month, 10) < 10) {
                fechaObj.month = '0' + fechaObj.month;
            }
            
            return fechaObj.day + '-' + fechaObj.month + '-' + fechaObj.year;
        } else {
            return '';
        }
    }
    
    public fechaStrToObj(fechaStr) {        
        if (fechaStr.trim() !== '' && fechaStr != null) {
            const fechaSplit = fechaStr.split('-');
            
            const fechaObj = {
                year: parseInt(fechaSplit[0], null),
                month: parseInt(fechaSplit[1], null),
                day: parseInt(fechaSplit[2], null)
            };
            
            return fechaObj;
        } else {
            return '';
        }
    }

    public dateToDatePicker(date){
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    
    public fechaDBToStr(fechaStrDB, inReverse: boolean = false) {
        if (fechaStrDB != null && fechaStrDB !== '') {
            const fechaDB = new Date(fechaStrDB);
            
            let day: any = fechaDB.getDate();
            let month: any = fechaDB.getMonth() + 1;
            const year = fechaDB.getFullYear();
            
            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = '0' + month;
            }
            
            let fechaStr;
            
            if (inReverse) {
                fechaStr = `${year}-${month}-${day}`;
            } else {
                fechaStr = `${day}-${month}-${year}`;
            }
            
            return fechaStr;
        } else {
            return '';
        }
        
    }
    
    public formatearFecha(fecha: string) {
        const arrFecha = fecha.split("-");
        
        const fechaStr = arrFecha[2] + '-' + arrFecha[1] + '-' + arrFecha[0];
        
        return fechaStr;
    }
    
    public horaObjToStr(horaObj) {
        console.log(horaObj);
        
        if (horaObj != null && horaObj !== '') {
            return horaObj.hour + ':' + horaObj.minute;
        } else {
            return '';
        }
        
    }
    
    public horaStrToObj(horaStr) {
        if (horaStr != null && horaStr.trim() !== '') {
            const horaSplit = horaStr.split(':');
            
            const horaObj = {
                hour: parseInt(horaSplit[0], null),
                minute: parseInt(horaSplit[1], null),
                second: 0
            };
            
            return horaObj;
        } else {
            return '';
        }
    }

    //Formato filtro automatico
    public formatFechaHoy(){
        var fechaHoy = new Date();
        var fechaHastaFiltro = this.formatDatePipeFilter(fechaHoy);

        return fechaHastaFiltro;
    }

    public formatFechaDesdeFiltro(dateHoy){

        var formatFechaDesdeMenosAnio = dateHoy.setFullYear(dateHoy.getFullYear()-1);
        var formatFechaDesdeFiltro = this.formatDatePipeFilter(formatFechaDesdeMenosAnio);

        return formatFechaDesdeFiltro;
    }

    // PIPE FORMAT DATE COMMONS - ANGULAR //
    // Metodo formatea fecha
    public formatDatePipe(date): string {
        var ddMMyyyy = this.datePipe.transform(new Date(date),"dd-MM-yyyy");
        return ddMMyyyy;
    }

    public formatDatePipeFilter(date): string {
        var yyyyMMdd = this.datePipe.transform(new Date(date),"yyyy-MM-dd");
        return yyyyMMdd;
    }

    // Metodo retorna nombre completo del mes y en español
    public formatDatePipe2(): string {
        var medium = this.datePipe.transform(new Date(),"MMMM d, y, h:mm:ss a");
        return medium;
    }

    public formatDateByFormat(date, format): string {
        var result = this.datePipe.transform(date, format);
        return result;
    }


     /* ----------Funciones para datepicker con rango:---------------- */

 

  public isHovered(selectedDate, fromDate, toDate, hoveredDate) {

    return fromDate && !toDate && hoveredDate && this.after(selectedDate, fromDate) && this.before(selectedDate, hoveredDate);

  }

 

  public isInside(selectedDate, fromDate, toDate) {

    return this.after(selectedDate, fromDate) && this.before(selectedDate, toDate);

  }

 

  public isFrom(selectedDate, fromDate) {

    return this.equals(selectedDate, fromDate);

  }

 

  public isTo(selectedDate, toDate) {

    return this.equals(selectedDate, toDate);

  }

  public formatDinero(precioFormateado){

    const formatterPeso = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    })


   /* var num = precioFormateado; 
    if(!isNaN(num)){
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/,'');
      precioFormateado = num;
    }*/

    return formatterPeso.format(precioFormateado);

  }

 

  equals = (one: NgbDateStruct, two: NgbDateStruct) =>

    one && two && two.year === one.year && two.month === one.month && two.day === one.day

 

  before = (one: NgbDateStruct, two: NgbDateStruct) =>

    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day

      ? false : one.day < two.day : one.month < two.month : one.year < two.year

 

  after = (one: NgbDateStruct, two: NgbDateStruct) =>

    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day

      ? false : one.day > two.day : one.month > two.month : one.year > two.year


    
}

