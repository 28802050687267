import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from './tool-box-theme/shared/shared.module';
import {TemplateModule} from './template/template.module';
import {TemplateService} from './template/template.service';
//Layout Component
import {TemplateComponent} from './template/template.component';
// Routing Module
import {AppRoutes} from './app.routing';
// App Component
import { AppComponent } from './app.component';
import { Page500Component } from './common/errors/500/500.component';
import { Page404Component } from './common/errors/404/404.component';
import { AuthComponent } from './common/auth/auth.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './providers/auth.interceptor';
import { CustomDatepickerI18n, I18n } from './utils/DatePickerI18n.Utils';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './utils/DatePickerFormater.Utils';
import { LoginComponent } from './common/login/login.component';
import { WelcomeComponent } from './common/welcome/welcome.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMasonryModule } from 'ngx-masonry';
import { StickyModule } from 'ng2-sticky-kit';
import { MiPerfilComponent } from './mi-perfil/mi.perfil.component';
import { ToastyModule } from 'ng2-toasty';
import { CommonModule, DatePipe } from '@angular/common';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { HttpErrorInterceptor } from './providers/http.error.interceptor';
import { DateValidator } from 'ng2-validation/dist/date';
import { cardPortletDelete, cardPortletRefresh } from './tool-box-theme/shared/directives/cards.directive';
import { RestablecerContrasenaComponent } from './common/restablecer-contrasena/restablecer.contrasena.component';
import { Steep1Component } from './canal-denuncias/steep1/steep1.component';
import { InicioComponent } from './canal-denuncias/inicio/inicio.component';
import { Steep2Component } from './canal-denuncias/steep2/steep2.component';
import { Steep3Component } from './canal-denuncias/steep3/steep3.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { Steep4Component } from './canal-denuncias/steep4/steep4.component';
import { UndefinedComponent } from './canal-denuncias/undefined/undefined.component';
import { SeguimientoComponent } from './canal-denuncias/seguimiento/seguimiento.component';
import { ResultadoDenunciaComponent } from './canal-denuncias/seguimiento/resultado/resultado.denuncia.component';
import { EstadoDenunciaComponent } from './canal-denuncias/administracion/estadodenuncias/estadodenuncia.component';
import { TiempoTranscurridoComponent } from './canal-denuncias/administracion/periodicidades/tiempo.transcurrido.component';
import { TipoDenunciaComponent } from './canal-denuncias/administracion/tipodenuncias/tipodenuncia.component';
import { TipoRelacionComponent } from './canal-denuncias/administracion/tiporelacion/tiporelacion.component';
import { RegionesComponent } from './canal-denuncias/administracion/regiones/regiones.component';
import { AdministracionCanalDenunciasComponent } from './canal-denuncias/administracion/administracion.component';
import { DelitosComponent } from './canal-denuncias/administracion/delitos/delitos.component';
import { DenunciasComponent } from './canal-denuncias/administracion/denuncias/denuncias.component';


registerLocaleData(es);


@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes, {useHash: true}),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TemplateModule,
        StickyModule,
        NgxMasonryModule,
        NgSelectModule,
        NgbModule,
        DataTablesModule,
        CommonModule,
        ToastyModule.forRoot()

    ],
    declarations: [
        AppComponent,
        TemplateComponent,
        Page500Component,
        Page404Component,
        LoginComponent,
        WelcomeComponent,
        MiPerfilComponent,
        AuthComponent,
        DateValidator,
        cardPortletRefresh,
        cardPortletDelete,
        RestablecerContrasenaComponent,
        InicioComponent,
        Steep1Component,
        Steep2Component,
        Steep3Component,
        Steep4Component,
        UndefinedComponent,
        SeguimientoComponent,
        ResultadoDenunciaComponent,
        EstadoDenunciaComponent,
        TiempoTranscurridoComponent,
        TipoDenunciaComponent,
        TipoRelacionComponent,
        RegionesComponent,
        AdministracionCanalDenunciasComponent,
        DelitosComponent,
        DenunciasComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-ES'},
        DatePipe,
        TemplateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
      },
        
        I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}
    ],
    bootstrap: [AppComponent]
})


export class AppModule {
}
