import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ModuloService } from 'src/app/central-portal/services/modulo.service';
import { SessionService } from 'src/app/providers/session.service';

@Component ({
    templateUrl: 'welcome.component.html'
})

export class WelcomeComponent {
    
    public user;
    public listModulos: any[] = [];

    constructor(public fb: FormBuilder, 
          public router: Router,
          private sessionService: SessionService,
          private moduloService: ModuloService) 
    {
      this.user = this.sessionService.getUsuario();
      this.cargaListaModulos(this.user);
    }

    public selectModulo(modulo) {
      this.sessionService.setModulo(JSON.stringify(modulo));
      this.router.navigate([modulo.routeLink]);
    }

    private cargaListaModulos(usuario) {
      this.moduloService.listModuloByUsuario(usuario).subscribe(
          (result) => {
              //console.log(result);
              this.listModulos = result['data'];
              //this.listaUsuariosDataTable();
          }
      );
    }
}