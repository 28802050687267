import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { TipoDenunciasService } from '../../services/tipo.denuncias.service';
import { DenunciasService } from '../../services/denuncias.service';
import { EstadoDenunciasService } from '../../services/estado.denuncias.service'; 
import { TipoDenuncia } from '../../model/tipo.denuncia.model';
import { Denuncia } from '../../model/denuncia.model';
import { EstadoDenuncia } from '../../model/estado.denuncia.model';
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import { SeguimientoDenuncia } from '../../model/seguimiento.denuncia.model';
import { CanalDenunciasService } from '../../services/canal.denuncias.service';
import { es_language } from 'src/app/utils/datetable.lenguaje.config';
import { TiempoTranscurridoDenunciasService } from '../../services/tiempo.transcurrido.denuncias.service';
import { TiempoTranscurridoComponent } from '../periodicidades/tiempo.transcurrido.component';
import { TimeUtils } from 'src/app/utils/Time.Utils';

@Component({
    selector: 'app-denuncias',
    templateUrl: './denuncias.component.html'
})
export class DenunciasComponent implements OnInit {

  constructor(
      private router: Router,
      private denunciasService: DenunciasService,
      private tipoDenunciaService: TipoDenunciasService,
      private estadoDenunciasService: EstadoDenunciasService,
      private tiempoTranscurridoDenunciasService: TiempoTranscurridoDenunciasService,
      private toastyService: ToastyService,
      private timeUtils: TimeUtils,
      private validarFormularioUtils: ValidarFormulariosUtils,
      private canalDenunciasService: CanalDenunciasService,

  ) {
      
  }

  formFilter = new FormGroup({
    filterTipoDenunciaControl: new FormControl(''),
    filterEstadoDenunciaControl: new FormControl(''),
    filterPeriodoDenunciaControl: new FormControl(''),
    filterFechaDesdeControl: new FormControl(),
    filterFechaHastaControl: new FormControl()
  });

  formCambiarEstadoDenuncia = new FormGroup({
    estadoDenunciaControl: new FormControl('', [Validators.required])
  });

  denuncias: Denuncia [] = [];
  tiposdenuncias: TipoDenuncia [] = [];
  estadosdenuncias: EstadoDenuncia[] = [];
  periodostiempo: TiempoTranscurridoComponent[] =[];
  denunciaSelected;
  tituloFormCambiarEstadoDenuncia = 'Cambio de Estado';
  editDenunciaMode = false;
  public table;
  public codigoSeguimiento;
  public codigoDenuncia;
  public passDenuncia;
  public identificador;
  public denuncia;
  public fechaRegistro;
  public tipoDenuncia;
  public estadoDenuncia;
  public itemDenunciado;
  public relacionProtermDenunciado;
  public regionHechos;
  public regionDetalle;
  public tiempoTranscurrido;
  public involucradosDetalle;
  public descripcionDetalle;
  public esAnonima;
  public nombreDenunciante;
  public nombre ='';
  public apellido='';
  public correo='';
  public numeroDocumento='';
  public comentarioSelected;
  public comentarios: any[] = [];
  public identificadorComentario;
  public tituloModal;
  public tituloDinamico;

  /* filter */
  public urlFilter='?';
  public filterEstado= '';
  public filterTipoDenuncia= '';
  public filterPeriodo='';
  public filterFechaDesde='';
  public filterFechaHasta='';

    ngOnInit() {

      this.cargaEstadosDenuncias();
      this.cargaPeriodosDenuncias();
      this.obtenerTiposDeDenunciaFilter();
      
      //this.urlFilter += '';
      //this.cargarDenuncias(this.urlFilter);

      if(this.canalDenunciasService.isAdmimnistrador 
        && this.canalDenunciasService.tipoAdministrador == 0){

          this.canalDenunciasService.canalDenuncias=true;
          this.urlFilter += '';
          this.cargarDenuncias(this.urlFilter);

      }else if (this.canalDenunciasService.isAdmimnistrador 
        && this.canalDenunciasService.tipoAdministrador == 1){

          this.formFilter.get('filterTipoDenunciaControl').setValue(1); //ley_20393 - prevencion del delito
          this.formFilter.get('filterTipoDenunciaControl').disable();
          //this.changeFiltroDenuncias();
          this.urlFilter += 'tipodenuncia=1';
          this.cargarDenuncias(this.urlFilter);

      }else if (this.canalDenunciasService.isAdmimnistrador
        && this.canalDenunciasService.tipoAdministrador == 2){

          this.formFilter.get('filterTipoDenunciaControl').setValue(2); //ley_karin
          this.formFilter.get('filterTipoDenunciaControl').disable();

          this.urlFilter += 'tipodenuncia=2';
          this.cargarDenuncias(this.urlFilter);

      }else{
          this.router.navigateByUrl('canal-denuncias/undefined');
      }
      

    }


    refrescaEstadoDenunciaModal(){
      this.codigoDenuncia =null;
      this.tituloFormCambiarEstadoDenuncia = 'Nuevo Registro';
      this.editDenunciaMode = false;
      this.formCambiarEstadoDenuncia.reset();
    
    }

    obtenerTiposDeDenunciaFilter(){
      this.tipoDenunciaService.getListaTipoDenuncias()
          .subscribe(
              (tiposdenuncias: any[]) => {               
                  this.tiposdenuncias = tiposdenuncias['data'];
              }
          );
    }

    cargaEstadosDenuncia() {
      this.estadoDenunciasService.getListaEstadoDenuncias()
          .subscribe(
              (estadosdenuncias: any[]) => {               
                  this.estadosdenuncias = estadosdenuncias['data'];
              }
          );
    }   

    cargaEstadosDenuncias() {

      this.estadoDenunciasService.getListaEstadoDenuncias()
        .subscribe(
          (estadodenuncia: any[]) => {
            this.estadosdenuncias = estadodenuncia['data'];
          }
        );
    }

    cargaPeriodosDenuncias() {

      this.tiempoTranscurridoDenunciasService.getListaTiempoTranscurrido()
        .subscribe(
          (periodos: any[]) => {
            this.periodostiempo = periodos['data'];
          }
        );
    }

    cargaFechaDesde(newDate) {
      this.filterFechaDesde = this.timeUtils.fechaObjToString(newDate);
      if(this.filterFechaDesde !== null && this.filterFechaDesde !== ''){
        this.changeFiltroDenuncias();
      }
      
    }
  
    cargaFechaHasta(newDate) {
      this.filterFechaHasta = this.timeUtils.fechaObjToString(newDate);
      if(this.filterFechaHasta !== null && this.filterFechaHasta !== ''){
        this.changeFiltroDenuncias();
      }
      
    }



    changeFiltroDenuncias() {

      const control = this.formFilter.controls;
      this.filterTipoDenuncia = control['filterTipoDenunciaControl'].value;
      this.filterEstado= control['filterEstadoDenunciaControl'].value; 
      this.filterPeriodo= control['filterPeriodoDenunciaControl'].value; 

      this.urlFilter = '?';

      if (this.filterTipoDenuncia !== null && this.filterTipoDenuncia !== '') {
        this.urlFilter += 'tipodenuncia=' + this.filterTipoDenuncia + '&';
      }else{
        this.filterTipoDenuncia = '0';
        this.urlFilter += 'tipodenuncia=' + this.filterTipoDenuncia + '&';
      }

      if (this.filterEstado !== null && this.filterEstado !== '') {
        this.urlFilter += 'estadodenuncia=' + this.filterEstado + '&';
      }else{
        this.filterEstado = '0';
        this.urlFilter += 'estadodenuncia=' + this.filterEstado + '&';
      } 

      if (this.filterPeriodo !== null && this.filterPeriodo !== '') {
        this.urlFilter += 'periododenuncia=' + this.filterPeriodo + '&';
      }else{
        this.filterPeriodo = '0';
        this.urlFilter += 'periododenuncia=' + this.filterPeriodo + '&';
      } 

      if (this.filterFechaDesde !== null && this.filterFechaDesde !== '') {
        this.urlFilter += 'fechadesde=' + this.filterFechaDesde + '&';
      }else{
        this.filterFechaDesde = '';
        this.urlFilter += 'fechadesde=' + this.filterFechaDesde + '&';
      }
      
      if (this.filterFechaHasta !== null && this.filterFechaHasta !== '') {
        this.urlFilter += 'fechahasta=' + this.filterFechaHasta + '&';
      }else{
        this.filterFechaHasta = '';
        this.urlFilter += 'fechahasta=' + this.filterFechaHasta + '&';
      }

      this.urlFilter = this.urlFilter.substring(0, this.urlFilter.length - 1);
      console.log(this.urlFilter)
      $('#tableDenuncias').DataTable().destroy();
      this.denunciaSelected = null;
      this.cargarDenuncias(this.urlFilter)
     
    }

    cargarDenuncias(urlFilter){

      console.log("filtro denuncias: "+ urlFilter)
      this.denunciasService.getListaDenunciasByFilter(urlFilter)
        .subscribe(
          (denuncia: any[]) => {
            this.denuncias = denuncia['data'];
            this.cargaTablaDenuncias(this.denuncias);
          }
        );
    }
  
    cargaTablaDenuncias(denuncias) {
      let numero = 0;
      this.table = $('#tableDenuncias').DataTable({
        responsive: true,
        searching: true,
        data: denuncias,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            title: 'Fecha Registro', data: 'fechaCreacion'
          },
          {
            title: 'Estado Denuncia', data: 'estadoDenuncia.nombre'
          },
          {
            title: 'Delito', data: 'item.nombre'
          },
          {
            title: 'Anónimo', data: 'anonimo'
          },
          {          
            title: 'Periodo de tiempo', data: 'periodo.nombre'
          },
          {
            title: 'Tipo Relación Denunciante', data: 'tipoRelacion.nombre'
          },
          {
            title: 'Región', data: 'region.nombreCorto'
          }
        ],
        language: es_language,
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.denunciaSelected = data;
          });
  
          return row;
        }
      });    
    }

    limpiarFechas(){
      this.formFilter.get('filterFechaDesdeControl').reset();
      this.formFilter.get('filterFechaHastaControl').reset();

      this.changeFiltroDenuncias();

  }

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formCambiarEstadoDenuncia.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formCambiarEstadoDenuncia.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formCambiarEstadoDenuncia.controls).forEach(key => {
            this.formCambiarEstadoDenuncia.get(key).markAsTouched();
            this.formCambiarEstadoDenuncia.get(key).updateValueAndValidity();
            if (this.formCambiarEstadoDenuncia.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarDenuncia() {
      let data = this.denunciaSelected;
      this.codigoDenuncia = data['id'];
      this.identificador = data['id'];
      $('#cambiarEstadoDenunciaModal').click();
      this.tituloFormCambiarEstadoDenuncia = 'Cambiar Estado';
      this.editDenunciaMode = true;
      this.formCambiarEstadoDenuncia.get('estadoDenunciaControl').setValue(data['estadoDenuncia.id']);
  
    }

    verRegistro(){

      let data = this.denunciaSelected;

      const seguimiento: SeguimientoDenuncia = {
        codigo: data['codigoSeguimiento'],
        clave: data['password'],
      }
      
      this.denunciasService.search(seguimiento)
            .subscribe(
                (output: any[]) => {
                const success = output['success'];
            
                    if (success) {
                        this.canalDenunciasService.identificadorDenuncia= output['data']['id'];
                        this.canalDenunciasService.isAdmimnistrador=true;
                        this.router.navigateByUrl('canal-denuncias/seguimiento/resultado');
                    } else {
                        swal(
                            'Error',
                            'El código y clave ingresado no coincide con ninguna denuncia registrada. Favor intentelo nuevamente.',
                            'error'
                        )
                    }

                }
            );
    }

    
    updateEstadoDenuncia() {
      
      const formValid = this.validarFormulario();
      if (formValid === true) {
      
        const control = this.formCambiarEstadoDenuncia.controls;
        const denuncia: Denuncia = {
          id: this.codigoDenuncia,
          estadoDenuncia: {id: control['estadoDenunciaControl'].value},
          activo: true
        };
        
        let mensajeExito = '';
        let mensajeError = '';
  
        if (this.editDenunciaMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.denunciasService.update(denuncia)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoDenuncia = null;
                  this.denunciaSelected = null;            
                  this.formCambiarEstadoDenuncia.reset();
                  this.tituloFormCambiarEstadoDenuncia = 'Cambiar Estado';
                  $('#btn-cerrar-modal-estado-denuncia').click();
                  $('#tableDenuncias').DataTable().destroy();
                  this.cargarDenuncias(this.urlFilter);
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } 
      }
    }

    volverAdministracion(){
        this.router.navigateByUrl('/canal-denuncias/administracion');

    }

}
