import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SessionService} from '../../../providers/session.service';
import {ToastOptions, ToastyConfig, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { TipoDenunciasService } from '../../services/tipo.denuncias.service';
import { TipoDenuncia } from '../../model/tipo.denuncia.model';

@Component({
    selector: 'app-tipodenuncia',
    templateUrl: './tipodenuncia.component.html'
})
export class TipoDenunciaComponent implements OnInit {

    constructor(
        private sessionService: SessionService,
        private tipoDenunciaService: TipoDenunciasService,
        private router: Router,
        private toastyService: ToastyService,

    ) {
       
    }

    formTipoDenuncia = new FormGroup({
        nombreTipoDenunciaControl: new FormControl('', [Validators.required]),
        activoTipoDenunciaControl: new FormControl('',null) 
    });

    tiposdenuncias: TipoDenuncia [] = [];
    codigoTipoDenuncia;
    tipoDenunciaSelected;
    tituloFormTipoDenuncia = 'Nuevo Registro';
    editTipoDenunciaMode = false;
    public table;

    ngOnInit() {
 
      this.cargaTiposDenuncias();

    }


    refrescaTipoDenunciasModal(){
      this.codigoTipoDenuncia =null;
      this.tituloFormTipoDenuncia = 'Nuevo Registro';
      this.editTipoDenunciaMode = false;
      this.formTipoDenuncia.reset();
    
    }


    cargaTiposDenuncias() {

      this.tipoDenunciaService.getListaTipoDenuncias()
        .subscribe(
          (tipodenuncia: any[]) => {
            this.tiposdenuncias = tipodenuncia['data'];
            this.cargaTablaTipoDenuncias(this.tiposdenuncias);
          }
        );
    }
  
    cargaTablaTipoDenuncias(tiposdenuncias) {
      let numero = 0;
      this.table = $('#tableTipoDenuncias').DataTable({
        responsive: true,
        searching: true,
        data: tiposdenuncias,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            width: "75%",
            title: 'Nombre', data: 'nombre'},
          {
            width: "20%",
            title: 'Estado', data: function (row) {
              if (row.activo) {
                return 'Activo';
              } else {
                return 'Inactivo';
              }
            }
          }
  
        ],
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.tipoDenunciaSelected = data;
          });
  
          return row;
        }
      });    
    }

   

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formTipoDenuncia.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formTipoDenuncia.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formTipoDenuncia.controls).forEach(key => {
            this.formTipoDenuncia.get(key).markAsTouched();
            this.formTipoDenuncia.get(key).updateValueAndValidity();
            if (this.formTipoDenuncia.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarTipoDenuncia() {
      let data = this.tipoDenunciaSelected;
      this.codigoTipoDenuncia = data['id'];
      $('#tipoDenunciaModal').click();
      this.tituloFormTipoDenuncia = 'Editar Registro';
      this.editTipoDenunciaMode = true;
      this.formTipoDenuncia.get('nombreTipoDenunciaControl').setValue(data['nombre']);
      this.formTipoDenuncia.get('activoTipoDenunciaControl').setValue(data['activo']); 
  
    }
    
    nuevoTipoDenuncia() {

      const formValid = this.validarFormulario();
      if (formValid === true) {
  
        const control = this.formTipoDenuncia.controls;
        const tipodenuncias: TipoDenuncia = {
          id: this.codigoTipoDenuncia,
          nombre: control['nombreTipoDenunciaControl'].value,
          activo: true
        };
  
        let mensajeExito = '';
        let mensajeError = '';
  
        if (!this.editTipoDenunciaMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.tipoDenunciaService.setNuevoTipoDenuncia(tipodenuncias)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoTipoDenuncia = null;
                  this.tipoDenunciaSelected = null;            
                  this.formTipoDenuncia.reset();
                  this.tituloFormTipoDenuncia = 'Nuevo Registro';
                  $('#btn-cerrar-modal-tipo-denuncia').click();
                  $('#tableTipoDenuncias').DataTable().destroy();
                  this.cargaTiposDenuncias();
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } else {
          tipodenuncias.activo = control['activoTipoDenunciaControl'].value;
          mensajeExito = 'Registro editado correctamente';
          mensajeError = 'Registro no pudo ser editado';
  
          this.tipoDenunciaService.setNuevoTipoDenuncia(tipodenuncias)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoTipoDenuncia = null;
                  this.tipoDenunciaSelected = null;         
                  this.formTipoDenuncia.reset();
                  this.tituloFormTipoDenuncia = 'Nuevo Registro';
                  $('#btn-cerrar-modal-tipo-denuncia').click();
                  $('#tableTipoDenuncias').DataTable().destroy();
                  this.cargaTiposDenuncias();
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
            
        }
      }
    }
  
    deleteTipoDenuncia(){
        swal({
          title: "Estás seguro que deseas eliminar el registro?",   
          text: "No podrás recuperar estos datos!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo eliminar!"
        }).then((confirm) => {
          if (confirm['value']) {
  
              /* eliminar registro */
              const id = this.tipoDenunciaSelected['id'];
              this.tipoDenunciaService.deleteTipoDenuncia(id)
              .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    if (success) {
                        this.addToast('Éxito', 'Registro eliminado exitosamente', 'success');
                        this.editTipoDenunciaMode=false;
                        this.tipoDenunciaSelected=null;
                        this.codigoTipoDenuncia=null;
                        $('#tableTipoDenuncias').DataTable().destroy();
                        this.cargaTiposDenuncias();                    
                    } else {
                        this.addToast('Error', 'No se pudo eliminar el registro', 'error');
                    }
                }
            );
          }
        });
    }

    volverAdministracion(){
      this.router.navigateByUrl('/canal-denuncias/administracion');
    }
}
