import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/providers/session.service';



@Component({
    templateUrl: 'auth.html'
})

export class AuthComponent implements OnInit, OnDestroy {

    private interval;

    constructor(private router: Router, private route: ActivatedRoute, 
                private sessionService: SessionService) {

    }


    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            console.log(params);

            const sesData = JSON.parse(params.get('token'));
            // aca se deberia llamar al servicio que guarda los datos en la sesion storage / local storage

            sessionStorage.setItem('username', sesData.username);
            sessionStorage.setItem('token', sesData.token);

            const jwt = sesData.token;
            const jwtData = jwt.split('.')[1];
            const decodedJwtJsonData = window.atob(jwtData);
            const detalleTrabajador = JSON.parse(decodedJwtJsonData);


            // console.log(decodedJwtJsonData);
            // console.log(detalleTrabajador.detalleTrabajador);

            this.sessionService.usuarioNombre = detalleTrabajador.detalleTrabajador.nombreCompleto;
            this.sessionService.usuarioDependencia = detalleTrabajador.detalleTrabajador.dependenciaCodigo;
            this.sessionService.usuarioGerencia = detalleTrabajador.detalleTrabajador.gerenciaCodigo;

            //console.log('sesion');
            //console.log(this.sessionService);


            // luego redireccionar al inicio de wizard
            this.interval = setInterval(() => {
                this.router.navigate(['dashboard']);
            }, 1);

        });
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }


}