import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoRelacionDenunciasService } from '../services/tipo.relacion.denuncias.service';
import { RegionDenunciasService } from '../services/region.denuncias.service';
import { ToastyUtils } from 'src/app/utils/toasty.utils';
import swal from 'sweetalert2'
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import { TIPOS_DENUNCIA } from '../services/constant.denuncias.service';
import { TiempoTranscurridoDenunciasService } from '../services/tiempo.transcurrido.denuncias.service';

@Component ({
    templateUrl: 'steep2.html'
})

export class Steep2Component implements OnInit  {

    public regiones?: any[] =[];
    public tiempos?: any[] =[];
    public relaciones?: any[] =[];
    public formData = null;
    public preguntaDinamicaByTipo;

    formSteep2 = new FormGroup({
      relacionController: new FormControl('', [Validators.required]),
      regionesController: new FormControl('', [Validators.required]),
      detalleLugarController: new FormControl('', [Validators.required]),
      tiempoTranscurridoController: new FormControl('', [Validators.required]),
      detalleInvolucradosController: new FormControl('', [Validators.required]),
      descripcionController: new FormControl('', [Validators.required]),
      adjuntoController: new FormControl('')
    })
    

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private tipoRelacionDenunciasService: TipoRelacionDenunciasService,
        private regionDenunciasService: RegionDenunciasService,
        private tiempoService: TiempoTranscurridoDenunciasService,
        private toastyUtils: ToastyUtils,
        private validarFormularioUtils: ValidarFormulariosUtils,
    ) { }

    ngOnInit() {

        if((this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.PREVENCION_DELITO ||
          this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.LEY_KARIN) && 
          (this.canalDenunciasService.itemDenuncia > 0)){

          this.cargaRegiones();
          this.cargaTiempos();
          this.cargaRelacionConEmpresa(this.canalDenunciasService.tipoDenuncia);

          if(this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.LEY_KARIN){
            this.preguntaDinamicaByTipo='1. ¿Quién interpone la denuncia?';
          }else{
            this.preguntaDinamicaByTipo='1. ¿Cual es su relación con Proterm?*';
          }

        }else{
          this.router.navigateByUrl('canal-denuncias/undefined');
        }

        if(this.canalDenunciasService.volverSteep3==true){
          this.formSteep2.get('regionesController').setValue(this.canalDenunciasService.regionDenuncia); 
          this.formSteep2.get('detalleLugarController').setValue(this.canalDenunciasService.detalleRegionDenuncia); 
          this.formSteep2.get('tiempoTranscurridoController').setValue(this.canalDenunciasService.tiempoDenuncia); 
          this.formSteep2.get('detalleInvolucradosController').setValue(this.canalDenunciasService.detalleInvolucradosDenuncia);
          this.formSteep2.get('descripcionController').setValue(this.canalDenunciasService.descripcion);   
        }
    }



    cargaRegiones() {
      this.regionDenunciasService.getListaRegiones()
      .subscribe(
          (lista: any[]) => {               
              this.regiones = lista['data'];
          }
      );
    }

    cargaTiempos() {
      this.tiempoService.getListaTiempoTranscurrido()
      .subscribe(
          (lista: any[]) => {               
              this.tiempos = lista['data'];
          }
      );
    }

    cargaRelacionConEmpresa(tipoDenuncia) {

      var urlFilter = '?';
      if (tipoDenuncia !== null && tipoDenuncia !== '') {
          urlFilter += 'tipodenuncia=' + tipoDenuncia + '&';
      }else{
        tipoDenuncia = '0';
        urlFilter += 'tipodenuncia=' + tipoDenuncia + '&';
      }

      this.tipoRelacionDenunciasService.getListaTipoRelacionByTipo(urlFilter)
      .subscribe(
          (lista: any[]) => {               
              this.relaciones = lista['data'];
          }
      );
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;
    
        if (!this.formSteep2.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);   
            const retClass = result ? '' : 'invalid';   
            return retClass;
        }
    }

    public isFormControlValid(formControl: string) {
        const control = this.formSteep2.get(formControl);
    
        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }

    uploadFile(Event){
      let fileToUpload = Event.target.files[0];    
        if(fileToUpload !=null){
          if(fileToUpload.size <= 3000000000){
            this.canalDenunciasService.formData = new FormData();
            this.canalDenunciasService.formData.append('file',fileToUpload);
          }else{
          this.toastyUtils.addToast('Error', 'El tamaño del documento no debe superar los 300 KB', 'error');
          }
        }else{
          this.canalDenunciasService.formData=null;
        } 
    }

    radioChecked(id, i){
        //array.forEach(item=>{
          //if(item.id !== id){ 
            // item.selected = false;
          //}else{
            // item.selected = true;
          //} 
        //})
    }

    

    irAPrevencionDelitoSteep1(){
      swal({
          title: "¿Estás seguro que deseas volver atrás?",   
          text: "Se perderán todos los cambios!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo volver!"
        }).then((confirm) => {
          if (confirm['value']) {
            this.canalDenunciasService.volverSteep2=true;
            this.router.navigateByUrl('/canal-denuncias/steep1');
          } 
      });      
    }

    irAPrevencionDelitoSteep3() { 
      
      if(this.validarFormularioUtils.validarFormulario(this.formSteep2)){
        const control = this.formSteep2.controls;

        this.canalDenunciasService.relacionDenuncia = control['relacionController'].value;
        this.canalDenunciasService.regionDenuncia = control['regionesController'].value;
        this.canalDenunciasService.detalleRegionDenuncia = control['detalleLugarController'].value;
        this.canalDenunciasService.tiempoDenuncia = control['tiempoTranscurridoController'].value;
        this.canalDenunciasService.detalleInvolucradosDenuncia = control['detalleInvolucradosController'].value;
        this.canalDenunciasService.descripcion = control['descripcionController'].value;
  
        this.router.navigateByUrl('/canal-denuncias/steep3');
      }else{
        swal(
            'Error',
            'Favor complete el formulario',
            'error'
          )
      }
      
    }


}