import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { TipoDenunciasService } from '../../services/tipo.denuncias.service';
import { TipoDenuncia } from '../../model/tipo.denuncia.model';
import { ItemDenunciasService } from '../../services/item.denuncias.service';
import { ItemTipoDenuncia } from '../../model/item.tipo.denuncia.model';

@Component({
    selector: 'app-delitos',
    templateUrl: './delitos.component.html'
})
export class DelitosComponent implements OnInit {

    constructor(
        private itemDenunciaService: ItemDenunciasService,
        private tipoDenunciaService: TipoDenunciasService,
        private router: Router,
        private toastyService: ToastyService,

    ) {
       
    }

    formFilter = new FormGroup({
      filterTipoDenunciaControl: new FormControl('')
      //filterDelitoControl: new FormControl('')
    });

    formDelitos = new FormGroup({
      tipoDenunciaControl: new FormControl('', [Validators.required]),
      tipoDelitoControl: new FormControl('', [Validators.required]),
      nombreDelitoControl: new FormControl('', [Validators.required]),
      activoDelitoControl: new FormControl('',null) 
    });

    delitosdenuncias: ItemTipoDenuncia [] = [];
    delitositemdependencia: ItemTipoDenuncia [] = [];
    tiposdenuncias: TipoDenuncia [] = [];
    codigoDelito;
    delitoSelected;
    tituloFormDelito = 'Nuevo Registro';
    editDelitoMode = false;
    public table;

    /* filter */
    public urlFilter='?';

    ngOnInit() {
      this.urlFilter += '';

      this.cargaDelitos(this.urlFilter);
      this.obtenerTiposDeDenunciaFilter();
      this.obtenerItemsDenunciaFilter();

    }

    refrescaDelitoModal(){
      this.codigoDelito =null;
      this.tituloFormDelito = 'Nuevo Registro';
      this.editDelitoMode = false;
      this.formDelitos.reset();
    }

    obtenerTiposDeDenunciaFilter(){
      this.tipoDenunciaService.getListaTipoDenuncias()
          .subscribe(
              (tiposdenuncias: any[]) => {               
                  this.tiposdenuncias = tiposdenuncias['data'];
              }
          );
    }

    obtenerItemsDenunciaFilter(){
      this.itemDenunciaService.getListaItemsDependencia()
          .subscribe(
              (delitositemdependencia: any[]) => {               
                  this.delitositemdependencia = delitositemdependencia['data'];
              }
          );
    }

    obtenerTiposDeDenuncia(){
      this.cargaTipoDenuncia();
    }

    cargaTipoDenuncia() {
      this.tipoDenunciaService.getListaTipoDenuncias()
          .subscribe(
              (tiposdenuncias: any[]) => {               
                  this.tiposdenuncias = tiposdenuncias['data'];
              }
          );
    }

    changeFiltro() {

      const control = this.formFilter.controls;
      let filterTipoDenuncia = control['filterTipoDenunciaControl'].value; 
      
      //let filterDelitoDependencia = control['filterDelitoControl'].value; 

      this.urlFilter = '?';

      if (filterTipoDenuncia !== null && filterTipoDenuncia !== '') {
        this.urlFilter += 'tipodenuncia=' + filterTipoDenuncia + '&';
      }else{
        filterTipoDenuncia = '0';
        this.urlFilter += 'tipodenuncia=' + filterTipoDenuncia + '&';
      }

      /*
      if (filterDelitoDependencia !== null && filterDelitoDependencia !== '') {
        filter += 'itemdependencia=' + filterDelitoDependencia + '&';
      }else{
        filterDelitoDependencia = '0';
        filter += 'itemdependencia=' + filterDelitoDependencia + '&';
      }
      */
      this.urlFilter = this.urlFilter.substring(0, this.urlFilter.length - 1);
      $('#tableDelitos').DataTable().destroy();
      this.delitoSelected = null;
      this.cargaDelitos(this.urlFilter)
     
    }

    cargaDelitos(filter){
      this.itemDenunciaService.getListaFilter(filter)
      .subscribe(
        (delitos: any[]) => {
          this.delitosdenuncias = delitos['data'];
          this.cargaTablaDelitos(this.delitosdenuncias);
        }
      );
    }
  
    cargaTablaDelitos(delitosdenuncias) {
      let numero = 0;
      this.table = $('#tableDelitos').DataTable({
        responsive: true,
        searching: true,
        data: delitosdenuncias,
        select: 'single',
        columns: [
          {
            width: "5%",
            title: '#', data: function (row) {
              numero = numero + 1;
              return numero;
            }
          },
          {
            width: "25%",
            title: 'Nombre', data: 'nombre'
          },
          {
            title: 'Ley', data: (row) => {
              if(row.tipoDenuncia!=null){
                return row['tipoDenuncia'].nombre;
              }else{
                return 'No definida';
              }    
            }
          },
          {
            width: "35%",
            title: 'Estado', data: function (row) {
              if (row.activo) {
                return 'Activo';
              } else {
                return 'Inactivo';
              }
            }
          }
  
        ],
        dom: 'ftlpr',
        buttons: [
          {
              text: 'My button',
              action: function ( e, dt, node, config ) {
                  alert( 'Button activated' );
              }
          }
      ],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.delitoSelected = data;
          });
  
          return row;
        }
      });    
    }

   

    // Verfica si el control cumple con las reglas de validación
    public isFormControlValid(formControl: string) {
        //traigo atributo del obj formSolicitud
        const control = this.formDelitos.get(formControl);

        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }


    public displayFieldCss(formControl: string) {
        let result: boolean;

        if (!this.formDelitos.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);

            const retClass = result ? '' : 'invalid';

            return retClass;
        }
    }

    addToast(titulo, mensaje, tipo) {
        const toastOptions: ToastOptions = {
            title: titulo,
            msg: mensaje,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        };

        switch (tipo) {
            case 'default':
                this.toastyService.default(toastOptions);
                break;
            case 'info':
                this.toastyService.info(toastOptions);
                break;
            case 'success':
                this.toastyService.success(toastOptions);
                break;
            case 'wait':
                this.toastyService.wait(toastOptions);
                break;
            case 'error':
                this.toastyService.error(toastOptions);
                break;
            case 'warning':
                this.toastyService.warning(toastOptions);
                break;
        }
    }


    validarFormulario() {
        let isValid = true;
        Object.keys(this.formDelitos.controls).forEach(key => {
            this.formDelitos.get(key).markAsTouched();
            this.formDelitos.get(key).updateValueAndValidity();
            if (this.formDelitos.get(key).invalid) {
                isValid = false;
            }
        });

        return isValid;

    }

    editarDelito() {
      let data = this.delitoSelected;
      this.codigoDelito = data['id'];
      $('#delitosModal').click();
      this.tituloFormDelito = 'Editar Registro';
      this.editDelitoMode = true;
      this.formDelitos.get('tipoDenunciaControl').setValue(data['tipoDenuncia.id']);
      this.formDelitos.get('nombreDelitoControl').setValue(data['nombre']);
      this.formDelitos.get('activoDelitoControl').setValue(data['activo']); 
  
    }
    
    nuevoDelito() {

      const formValid = this.validarFormulario();
      if (formValid === true) {
  
        const control = this.formDelitos.controls;
        const itemtipodenuncia: ItemTipoDenuncia = {
          id: this.codigoDelito,
          tipoDenuncia: {id: control['tipoDenunciaControl'].value},
          nombre: control['nombreDelitoControl'].value,
          activo: true
        };
  
        let mensajeExito = '';
        let mensajeError = '';
  
        if (!this.editDelitoMode) {
          mensajeExito = 'Registro agregado correctamente';
          mensajeError = 'Registro no pudo ser agregado';
  
          this.itemDenunciaService.setNuevoDelito(itemtipodenuncia)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];       
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoDelito = null;
                  this.delitoSelected = null;            
                  this.formDelitos.reset();
                  this.tituloFormDelito = 'Nuevo Registro';
                  $('#btn-cerrar-modal-delitos').click();
                  $('#tableDelitos').DataTable().destroy();
                  this.cargaDelitos(this.urlFilter);
  
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
        } else {
          itemtipodenuncia.activo = control['activoDelitoControl'].value;
          mensajeExito = 'Registro editado correctamente';
          mensajeError = 'Registro no pudo ser editado';
  
          this.itemDenunciaService.setNuevoDelito(itemtipodenuncia)
            .subscribe(
              (output: any[]) => {
                const success = output['success'];
                if (success) {
                  this.addToast('Éxito', mensajeExito, 'success');
                  this.codigoDelito = null;
                  this.delitoSelected = null;         
                  this.formDelitos.reset();
                  this.tituloFormDelito = 'Nuevo Registro';
                  $('#btn-cerrar-modal-delitos').click();
                  $('#tableDelitos').DataTable().destroy();
                  this.cargaDelitos(this.urlFilter);
                } else {
                  this.addToast('Error', mensajeError, 'error');
                }
              }
            );
            
        }
      }
    }
  
    deleteDelito(){
        swal({
          title: "Estás seguro que deseas eliminar el registro?",   
          text: "No podrás recuperar estos datos!",   
          type: "warning",   
          showCancelButton: true, 
          cancelButtonText: "Cancelar",  
          confirmButtonColor: "#DD6B55",   
          confirmButtonText: "Si, deseo eliminar!"
        }).then((confirm) => {
          if (confirm['value']) {
  
              /* eliminar registro */
              const id = this.delitoSelected['id'];
              this.itemDenunciaService.deleteDelito(id)
              .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    if (success) {
                        this.addToast('Éxito', 'Registro eliminado exitosamente', 'success');
                        this.editDelitoMode=false;
                        this.delitoSelected=null;
                        this.codigoDelito=null;
                        $('#tableDelitos').DataTable().destroy();
                        this.cargaDelitos(this.urlFilter);                    
                    } else {
                        this.addToast('Error', 'No se pudo eliminar el registro', 'error');
                    }
                }
            );
          }
        });
    }

    volverAdministracion(){
      this.router.navigateByUrl('/canal-denuncias/administracion');
    }

}
