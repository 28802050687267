import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ItemDenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public isDenunciante: Boolean;


    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
    }

    public getListaItems() {
      return this.http.get(this.apiURL + 'item/list');
    } 

    public getListaItemsDependencia() {
      return this.http.get(this.apiURL + 'item/listItemDependencia');
    } 

    public getListaItemsByTipo(tipoDenuncia){
      return this.http.get(this.apiURL + 'item/listByTipoDenuncia/' + tipoDenuncia);
    }

    public getListaFilter(filter){
      return this.http.get(this.apiURL + 'item/listByFilter/' + filter);
    }

    public setNuevoDelito(delito) {
      return this.http.post(`${this.apiURL}item/save`, delito);
    }
  
    public deleteDelito(delito) {
      return this.http.delete(this.apiURL + 'item/delete/' + delito);
    }
}
