import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastyUtils } from 'src/app/utils/toasty.utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import swal from 'sweetalert2'
import { CanalDenunciasService } from '../../services/canal.denuncias.service';
import { DenunciasService } from '../../services/denuncias.service';
import { SeguimientoDenuncia } from '../../model/seguimiento.denuncia.model';
import * as $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select';
import 'datatables.net-buttons';
import { es_language } from 'src/app/utils/datetable.lenguaje.config';
import { ComentarioDenuncia } from '../../model/comentario.denuncia.model';

@Component ({
    templateUrl: 'resultado.denuncia.html'
})

export class ResultadoDenunciaComponent implements OnInit  {

    public codigoDenuncia;
    public passDenuncia;
    public identificador;
    public denuncia;
    public fechaRegistro;
    public tipoDenuncia;
    public estadoDenuncia;
    public itemDenunciado;
    public relacionProtermDenunciado;
    public regionHechos;
    public regionDetalle;
    public tiempoTranscurrido;
    public involucradosDetalle;
    public descripcionDetalle;
    public esAnonima;
    public nombreDenunciante;
    public nombre ='';
    public apellido='';
    public correo='';
    public numeroDocumento='';
    public table;
    public comentarioSelected;
    public comentarios: any[] = [];
    public identificadorComentario;
    public tituloModal;
    public tituloDinamico;

    public isAdmin;


    /* Adjunto */
    public formData = null;

    formResult = new FormGroup({
        //codigoController: new FormControl('', [Validators.required]),
        //claveDenunciaController: new FormControl('', [Validators.required])
    })

    formComentario = new FormGroup({
        comentarioControl: new FormControl('', [Validators.required]),
        adjuntoController: new FormControl('')
    })

    formAdjunto = new FormGroup({
    })

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private denunciaService: DenunciasService,
        private toastyUtils: ToastyUtils,
        private validarFormularioUtils: ValidarFormulariosUtils,

    ) { }

    ngOnInit() {
    
        this.identificador = this.canalDenunciasService.identificadorDenuncia;
        this.isAdmin = this.canalDenunciasService.isAdmimnistrador;
        this.setResultSeguimiento(this.identificador);
    }

    setResultSeguimiento(identificador){

        this.denunciaService.get(identificador)
        .subscribe(
            (output: any[]) => {               
                
                this.denuncia = output['data'];
                this.codigoDenuncia = this.denuncia.codigoSeguimiento;
                this.fechaRegistro = this.denuncia.fechaSuceso;
                this.estadoDenuncia = this.denuncia['estadoDenuncia'].nombre;
                this.tipoDenuncia = this.denuncia['item']['tipoDenuncia'].nombre;
                this.itemDenunciado = this.denuncia['item'].nombre;
                this.relacionProtermDenunciado = this.denuncia['tipoRelacion'].nombre;
                this.regionHechos = this.denuncia['region'].nombre;
                this.regionDetalle = this.denuncia.detalleRegion;
                this.tiempoTranscurrido = this.denuncia['periodo'].nombre;
                this.involucradosDetalle = this.denuncia.detalleInvolucrados;
                this.descripcionDetalle = this.denuncia.descripcionHechos;
                if(this.denuncia.anonimo != null){
                    if(this.denuncia.anonimo == '1'){
                        this.esAnonima = 'Si';
                    }else if(this.denuncia.anonimo == '2'){
                        this.esAnonima = 'No';

                        if(this.denuncia.nombreDenunciante != null){
                            this.nombre = this.denuncia.nombreDenunciante;
                        }

                        if(this.denuncia.apellidoDenunciante != null){
                            this.apellido = this.denuncia.apellidoDenunciante;
                        }

                        if(this.denuncia.numeroDocumentoDenunciante != null){
                            this.numeroDocumento = this.denuncia.numeroDocumentoDenunciante;
                        }

                        if(this.denuncia.correoDenunciante != null){
                            this.correo = this.denuncia.correoDenunciante;
                        }else{
                            this.correo = 'No informado';
                        }

                        this.nombreDenunciante= this.nombre + ' '+ this.apellido;
                    }
                }
                this.tituloModal='Agregar Comentario a la Denuncia';


                if(this.denuncia['item']['tipoDenuncia'].id == 1){
                    this.tituloDinamico= '1. Relación con Proterm:';
                }else{
                    this.tituloDinamico= '1. Quién interpuso la denuncia:';
                }
                
                this.cargarComentarios(identificador);

            }
        );
    }

    cargarComentarios(id){
        /* obtener lista de comentarios de la denuncia */
        this.denunciaService.getListaComentarios(id)
        .subscribe(
            (obj: any[]) => {
                this.comentarios = obj['data'];
                this.cargaTablaComentarios(this.comentarios);
            }
        );

    }


    cargaTablaComentarios(comentarios) {
        let numero = 0;
        this.table = $('#comentariosTable').DataTable({
          //responsive: true,
          order: [ 0, "desc" ],
          searching: false,
          paging:   false,
          scrollX: true,
          data: comentarios,
          select: 'single',
          pageLength: 50,
          columns: [
            { title: 'Fecha Comentario', data: 'fechaCreacion'},
            { title: 'Enviado por', data: 'responsable'},
            //{ title: 'Comentario', data: 'mensaje'},
            { title: 'Comentario', data: function(row){
                return row.mensaje.substr(0, 40)+'...<a id="vercomentario" href="javascript:void(0);" title="Ver Comentario completo"> Ver más</a>';
                }
              },
            { title: 'Adjunto',  className: "dt-body-center", data: (row) => {
                let button='';
                if(row.adjunto == 'ok'){
                  button+='<button class="btn btn-xs btn-info" id="documento" href="javascript:void(0);" title="Descargar Documento"><i class="ei-cloud-download"></i></button>';
                }else{
                  button+='';
                }
                return button;
                }
            }
            /*{ title: 'Codigo OC', data: function(row){
    
              return '<a id="getpdf" href="javascript:void(0);" title="Descargar PDF">'+ row.ordenCompra.id +'</a>';
              }
            },*/
            //{ title: 'Estado', data: 'estadoSolicitud.nombre'},
            //{ title: 'Solicitante', data: 'solicitanteNombre'},
            //{ title: 'Proveedor', data: 'proveedor.nombre'},
            // { title: 'Moneda', data: 'moneda.nombre'},
            //{ title: 'Tipo Factura', data: 'tipoFactura.nombre'},
            //{ title: 'Condicion Pago', data: 'condicionPago.nombre'},
            //{ title: 'Aprobador', data: 'aprobadorSolicitud.nombreCompleto'},
            //{ title: 'Sucursal', data: 'sucursal.nombre'}
    
          ],
          language: es_language,
          dom: 'ftlpr',
          buttons: [
            {
                text: 'My button',
                action: function ( e, dt, node, config ) {
                    alert( 'Button activated' );
                }
            }
        ],
          rowCallback: (row: Node, data: any[] | Object, index: number) => {
            const self = this;
            
            $('td #vercomentario', row).bind('click', () => {
                self.cargarComentarioModal(data);
               
            });

            $('td #documento', row).bind('click', () => {
                self.descargarDocumento(data);
            });

            $('td', row).unbind('click');

            $('td', row).bind('click', () => {
              self.comentarioSelected = data;
            });
    
            return row;
          }
        });    
    }


    cargarComentarioModal(dataSelected){
        this.comentarioSelected=dataSelected;
        $('#modal-emitir-comentario-abrir').click();
        this.tituloModal = 'Ver comentario';
        this.formComentario.get('comentarioControl').setValue(this.comentarioSelected['mensaje']);
    }

    descargarDocumento(comentario){
        const nameFile = comentario.nombreAdjunto;
        const result = this.denunciaService.getDocumento(comentario.id);
        result.subscribe(
            (success: any) => {
                const blob = new Blob([success], {type: 'application/octet-stream'});
                if(window.navigator && window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, nameFile);
                }else{
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = nameFile;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                   
                }
            },
            err => {
                console.log('Error: '+ JSON.stringify(err));
            }
        );
    
    }

    descargarPdf(){
        if(this.identificador!= null){

          const nameFile ='reporte_denuncia_'+this.codigoDenuncia+'.pdf';
          const result = this.denunciaService.exportarDenunciaPdf(this.identificador);
          result.subscribe(
              (success: any) => {
                  const blob = new Blob([success], {type: 'application/pdf'});
                  this.toastyUtils.addToast('Reporte PDF', 'El reporte ha sido generado', 'success');
                  if(window.navigator && window.navigator.msSaveOrOpenBlob){
                      window.navigator.msSaveOrOpenBlob(blob, nameFile);
                  }else{
                      const a = document.createElement('a');
                      a.href = URL.createObjectURL(blob);
                      a.download = nameFile;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                     
                  }
              },
              err => {
                  this.toastyUtils.addToast('Error', 'Ha ocurrido un error al intentar generar el reporte', 'error');
              }
          );
      
        }else{
          this.toastyUtils.addToast('Error', 'Ha ocurrido un error al intentar generar el reporte', 'error');
        } 
    }

    salir(){
        this.router.navigateByUrl('/canal-denuncias');
    }

    volverDenuncias(){
        this.router.navigateByUrl('/canal-denuncias');
    }

    refrescarFormularioComentario(){
        this.tituloModal='Agregar Comentario a la Denuncia';
        this.formComentario.reset();
        this.comentarioSelected=null;
    }

    uploadFile(Event){
        let fileToUpload = Event.target.files[0];
       
           if(fileToUpload !=null){
             if(fileToUpload.size <= 3000000000){

               this.formData = new FormData();
               this.formData.append('file',fileToUpload);
             }else{
                this.toastyUtils.addToast('Error', 'El tamaño del documento no debe superar los 300 KB', 'error');
             }
           }else{
             this.formData=null;
           } 
    }

    adjuntarFichero(){
        if(this.formData !=null){                
            this.denunciaService.uploadFile(this.identificador,this.formData).subscribe(
              resp => {
                if(resp['success']){
                    this.toastyUtils.addToast('Se ha cargado el documento exitosamente', '', 'success');
                    this.identificador =null;
                  
                }else{
                    this.toastyUtils.addToast('Error', 'Error al cargar el documento, favor intentelo nuevamente', 'error');
                    this.identificador =null;
                }
              },
              error => {
                this.identificador =null;
                this.toastyUtils.addToast('Error','Error al cargar el documento','error');
              }
            );
          }else{
            this.identificador =null;
          }
    }

    nuevoComentario(){
        if(this.validarFormularioUtils.validarFormulario(this.formComentario)){
            const control = this.formComentario.controls;

            const comentario: ComentarioDenuncia = {
                denuncia: {id: this.identificador},
                mensaje: control['comentarioControl'].value,
                isAdmin: this.isAdmin,
                activo: true
            };
    
            console.log("comentario: ", comentario);
            this.denunciaService.saveComentario(comentario)
            .subscribe(
                (output: any[]) => {
                    const success = output['success'];
                    
                    if (success) {
          
                        this.identificadorComentario = output['data']['id'];
            
                        if(this.formData !=null){ 
                            this.denunciaService.nuevoComentarioAdjunto(this.identificadorComentario,this.formData).subscribe(
                                resp => {
                                    if(resp['success']){
    
                                        this.toastyUtils.addToast('Se ha cargado el documento exitosamente', '', 'success');      
                                        this.formComentario.reset();
                                        $('#btn-cerrar-modal-comentario').click();
                                        $('#comentariosTable').DataTable().destroy();
                                        this.cargarComentarios(this.identificador); 
    
                                    }else{
                                        this.toastyUtils.addToast('Error', 'Error al registrar comentario, favor intentelo nuevamente', 'error');
                                    }
                                },
                                error => {
                                swal(
                                    'Error',
                                    'El adjunto no ha podido ser cargado de forma exitosa. El registro se ha efectuado de todas formas.',
                                    'error'
                                )
                                }
                            );
                        }else{
                            this.toastyUtils.addToast('Se ha cargado el documento exitosamente', '', 'success');      
                            this.formComentario.reset();
                            this.identificadorComentario = null;
                            $('#btn-cerrar-modal-comentario').click();
                            $('#comentariosTable').DataTable().destroy();
                            this.cargarComentarios(this.identificador);     
    
                        }
          
                    } else {
                        swal(
                            'Error',
                            'Se ha producido un error al intentar registrar el comentario',
                            'error'
                        )
                    }
          
                }
            );

        }else{
            this.toastyUtils.addToast('Error', 'Ingrese un comentario y luego registrar', 'error');
        }
        
    }

    volverListadoDenuncias(){
        this.router.navigateByUrl('/canal-denuncias/administracion/denuncias');
    }


}