import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TiempoTranscurridoDenunciasService {

    apiURL = environment.apiCanalDenuncias;

    public canalDenuncias: Boolean;
    public isAdmimnistrador: Boolean;
    public isDenunciante: Boolean;


    constructor(private http: HttpClient) {
      this.canalDenuncias=true;
      this.isAdmimnistrador=false;
      this.isDenunciante=false;
    }

    public getListaTiempoTranscurrido() {
      return this.http.get(this.apiURL + 'periodicidad/list');
    } 

    public setNuevoTiempoTranscurrido(tiempotranscurrido) {
      return this.http.post(`${this.apiURL}periodicidad/save`, tiempotranscurrido);
    }
  
    public deleteTiempoTranscurrido(tiempotranscurrido) {
      return this.http.delete(this.apiURL + 'periodicidad/delete/' + tiempotranscurrido);
    }
}
