import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalDenunciasService } from '../services/canal.denuncias.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastyUtils } from 'src/app/utils/toasty.utils';
import { ItemDenunciasService } from '../services/item.denuncias.service';
import swal from 'sweetalert2'
import { ValidarFormulariosUtils } from 'src/app/utils/validar.formularios.utils';
import { TIPOS_DENUNCIA } from '../services/constant.denuncias.service';

@Component ({
    templateUrl: 'steep1.html'
})

export class Steep1Component implements OnInit  {

    public items?: any[] =[];
    public titulo?: string;

    formSteep1 = new FormGroup({
        itemController: new FormControl('', [Validators.required])
    })

    constructor(
        private router: Router,
        private canalDenunciasService: CanalDenunciasService,
        private toastyUtils: ToastyUtils,
        private itemDenunciasService: ItemDenunciasService,
        private validarFormularioUtils: ValidarFormulariosUtils,
    ) { }

    ngOnInit() {

        if(this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.PREVENCION_DELITO){
            this.titulo = 'Material de denuncia.';
        }else if(this.canalDenunciasService.tipoDenuncia == TIPOS_DENUNCIA.LEY_KARIN ){
            this.titulo = 'Material de denuncia de ley 21.643.';
        }else{
            this.router.navigateByUrl('canal-denuncias/undefined');
        }

        this.cargaItems(this.canalDenunciasService.tipoDenuncia);

        $('#'+this.canalDenunciasService.itemDenuncia).prop('checked', true);


    }

    cargaItems(tipoDenuncia) {

        var urlFilter = '?';
        if (tipoDenuncia !== null && tipoDenuncia !== '') {
            urlFilter += 'tipodenuncia=' + tipoDenuncia + '&';
        }else{
          tipoDenuncia = '0';
          urlFilter += 'tipodenuncia=' + tipoDenuncia + '&';
        }

        this.itemDenunciasService.getListaItemsByTipo(urlFilter)
            .subscribe(
                (listaItems: any[]) => {               
                    this.items = listaItems['data'];
                }
            );
    }

    public displayFieldCss(formControl: string) {
        let result: boolean;
    
        if (!this.formSteep1.get(formControl).touched) {
            return '';
        } else {
            result = this.isFormControlValid(formControl);   
            const retClass = result ? '' : 'invalid';   
            return retClass;
        }
    }

    public isFormControlValid(formControl: string) {
        const control = this.formSteep1.get(formControl);
    
        if (control.invalid) {
            return false;
        } else {
            return true;
        }
    }

    irAInicio(){
        swal({
            title: "¿Estás seguro que deseas Cancelar?",   
            text: "Se perderán todos los cambios!",   
            type: "warning",   
            showCancelButton: true, 
            cancelButtonText: "Cancelar",  
            confirmButtonColor: "#DD6B55",   
            confirmButtonText: "Si, deseo volver!"
          }).then((confirm) => {
            if (confirm['value']) {

                this.canalDenunciasService.canalDenuncias = null;
                this.canalDenunciasService.apellidoDenunciante = null; 
                this.canalDenunciasService.correoDenunciante = null;
                this.canalDenunciasService.descripcion = null;
                this.canalDenunciasService.detalleInvolucradosDenuncia = null;
                this.canalDenunciasService.detalleRegionDenuncia = null;
                this.canalDenunciasService.esAnonimo= null;
                this.canalDenunciasService.isAdmimnistrador= null;
                this.canalDenunciasService.isDenunciante=null;
                this.canalDenunciasService.itemDenuncia=null;
                this.canalDenunciasService.nombreDenunciante=null;
                this.canalDenunciasService.regionDenuncia=null;
                this.canalDenunciasService.relacionDenuncia=null;
                this.canalDenunciasService.tiempoDenuncia=null;
                this.canalDenunciasService.tipoDenuncia=null;
                this.canalDenunciasService.volverSteep1=null;
                this.canalDenunciasService.volverSteep2=null;
                this.canalDenunciasService.volverSteep3=null;
                this.canalDenunciasService.identificadorDenuncia = null;
                this.canalDenunciasService.codigoDenuncia = null;
                this.canalDenunciasService.passwordDenunciante = null;
                
                this.router.navigateByUrl('/canal-denuncias');
            } 
        });      
    }

    irAPrevencionDelitoSteep2() {

        if(this.validarFormularioUtils.validarFormulario(this.formSteep1)){
            const control = this.formSteep1.controls;
            this.canalDenunciasService.itemDenuncia = control['itemController'].value;
            this.router.navigateByUrl('/canal-denuncias/steep2');
        }else{
            swal(
                'Error',
                'Favor responda una opción',
                'error'
              )
        }

       
    }

}